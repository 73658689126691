import {
  getPureText,
  getBodyWithoutEmbeds,
  decodeHTMLEntities,
} from 'utils/getPureText';

/**
 * Получение текста без тегов и прочего из clusterBody
 * @param clusterBody - тело кластера
 */
const prepareClusterBody = (clusterBody: ClusterData['body']) => {
  const withoutEntities = decodeHTMLEntities(clusterBody);
  const prepared = getBodyWithoutEmbeds(withoutEntities).replace(
    /&nbsp;/g,
    ' ',
  );

  return getPureText(prepared);
};

/**
 * Подготовка текста кластера для воспроизведения
 * @param clusterTitle - заголовок кластера;
 * @param clusterBody - тело кластера;
 */
export const getClusterSpeechText = (
  clusterTitle: ClusterData['longTitle'],
  clusterBody: ClusterData['body'],
): string => {
  const preparedBody = prepareClusterBody(clusterBody);

  return `${clusterTitle}.\n ${preparedBody}`;
};
