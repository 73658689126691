import {
  OuterPropsType,
  H1,
  H2,
  H3,
  JSXToBlockRenderer,
  JSXToEntityRenderer,
  Image,
  Link,
  OrderedList,
  UnorderedList,
  Video,
  Youtube,
  BLOCK_TYPES,
  ENTITY_TYPES,
} from '@media-components/draft-parser';

import { CUSTOM_BLOCK_TYPE, OuterDraftPropsType } from '../typings';

import { ArticleMediaSectionBlock } from './components/ArticleMediaSection';
import { ArticleMediaSectionBlockMobile } from './components/ArticleMediaSectionMobile';
import ArticleMediaSectionMobileStyles from './components/ArticleMediaSectionMobile.css';
import { Banner } from './components/Banner';
import BannerStyles from './components/Banner.css';
import { Blockquote } from './components/Blockquote';
import BlockquoteStyles from './components/Blockquote.css';
import { Expert } from './components/Expert';
import ExpertStyles from './components/Expert/styles.module.css';
import H1Styles from './components/H1.css';
import H2Styles from './components/H2.css';
import H3Styles from './components/H3.css';
import ImgStyles from './components/Image.css';
import ImageRedesignStyles from './components/ImageRedesign.css';
import ClusterLinkStyles from './components/Link.css';
import MulticlusterLinkStyles from './components/MulticlusterLink.css';
import MulticlusterRTagStyles from './components/MulticlusterRTag.css';
import OrderedListStyles from './components/OrderedList.css';
import OLRedesignStyles from './components/OrderedListRedesign.css';
import { Related } from './components/Related';
import RelatedStyles from './components/Related.css';
import { ResourceRelated } from './components/ResourceRelated';
import ResourceRelatedStyles from './components/ResourceRelated.css';
import { RTag } from './components/RTag';
import ClusterRTagStyles from './components/RTag.css';
import UnorderedListStyles from './components/UnorderedList.css';
import ULRedesignStyles from './components/UnorderedListRedesign.css';
import VideoStyles from './components/Video.css';
import { VideoPlatform, VideoPlatformLive } from './components/VideoPlatform';
import YoutubeStyles from './components/Youtube.css';

const MOBILE_CUSTOM_BLOCKS = {
  [CUSTOM_BLOCK_TYPE.ArticleMediaSectionMobile]: JSXToBlockRenderer({
    Component: ArticleMediaSectionBlockMobile,
    styles: ArticleMediaSectionMobileStyles,
  }),
  [CUSTOM_BLOCK_TYPE.Related]: JSXToBlockRenderer({
    Component: Related,
    styles: RelatedStyles,
  }),
  [CUSTOM_BLOCK_TYPE.ResourceRelated]: JSXToBlockRenderer({
    Component: ResourceRelated,
    styles: ResourceRelatedStyles,
  }),
};

const DESKTOP_CUSTOM_BLOCKS = {
  [CUSTOM_BLOCK_TYPE.ArticleMediaSection]: JSXToBlockRenderer({
    Component: ArticleMediaSectionBlock,
  }),
};

export const getRender = ({
  forceRedesign,
  isMulticluster,
  isMobile,
  outerProps,
}: {
  forceRedesign: boolean;
  isMulticluster: boolean;
  isMobile: boolean;
  outerProps: OuterPropsType<OuterDraftPropsType>;
}) => {
  const LinkStyles = isMulticluster
    ? MulticlusterLinkStyles
    : ClusterLinkStyles;
  const RTagStyles = isMulticluster
    ? MulticlusterRTagStyles
    : ClusterRTagStyles;

  const ULStyles = forceRedesign ? ULRedesignStyles : UnorderedListStyles;
  const ImageStyles = forceRedesign ? ImageRedesignStyles : ImgStyles;
  const OLStyles = forceRedesign ? OLRedesignStyles : OrderedListStyles;

  const additionalObjects = isMobile
    ? MOBILE_CUSTOM_BLOCKS
    : DESKTOP_CUSTOM_BLOCKS;

  return {
    blocks: {
      [BLOCK_TYPES.H1]: JSXToBlockRenderer({ Component: H1, styles: H1Styles }),
      [BLOCK_TYPES.H2]: JSXToBlockRenderer({ Component: H2, styles: H2Styles }),
      [BLOCK_TYPES.H3]: JSXToBlockRenderer({ Component: H3, styles: H3Styles }),
      /** H4 - H6 референсов не имеют, поэтому пока без них */
      [BLOCK_TYPES.Blockquote]: JSXToBlockRenderer({
        Component: Blockquote,
        styles: BlockquoteStyles,
      }),
      [BLOCK_TYPES.ULItem]: JSXToBlockRenderer({
        Component: UnorderedList,
        styles: ULStyles,
        outerProps,
      }),
      [BLOCK_TYPES.OLItem]: JSXToBlockRenderer({
        Component: OrderedList,
        styles: OLStyles,
        outerProps,
      }),

      /** Кастомные блоки, специфичные для кластера */
      [CUSTOM_BLOCK_TYPE.Banner]: JSXToBlockRenderer({
        Component: Banner,
        styles: BannerStyles,
        outerProps,
      }),
      ...additionalObjects,
    },
    entities: {
      [ENTITY_TYPES.Link]: JSXToEntityRenderer({
        Component: Link,
        styles: LinkStyles,
        outerProps,
      }),
      [ENTITY_TYPES.VideoPlatform]: JSXToEntityRenderer({
        Component: VideoPlatform,
        outerProps,
      }),
      [ENTITY_TYPES.VideoPlatformLive]: JSXToEntityRenderer({
        Component: VideoPlatformLive,
        outerProps,
      }),
      [ENTITY_TYPES.Video]: JSXToEntityRenderer({
        Component: Video,
        styles: VideoStyles,
        outerProps,
      }),
      [ENTITY_TYPES.RTag]: JSXToEntityRenderer({
        Component: RTag,
        styles: RTagStyles,
        outerProps,
      }),
      [ENTITY_TYPES.Image]: JSXToEntityRenderer({
        Component: Image,
        styles: ImageStyles,
        outerProps,
      }),
      [ENTITY_TYPES.Youtube]: JSXToEntityRenderer({
        Component: Youtube,
        styles: YoutubeStyles,
        outerProps,
      }),
      [ENTITY_TYPES.Expert]: JSXToEntityRenderer({
        Component: Expert,
        styles: ExpertStyles,
      }),
    },
  };
};
