import { useEffect } from 'react';

import { ROUTER_UPDATE_EVENT_NAME } from './contants';

type useClusterChangeUrlRedisignListenerType = (
  cb: EventListenerOrEventListenerObject,
) => void;

/**
 * Хук для прослушивания события смены роута.
 * @param cb - функция, реагирующая на смену страницы.
 */
export const useClusterChangeUrlListener: useClusterChangeUrlRedisignListenerType =
  (cb) => {
    useEffect(() => {
      window.addEventListener(ROUTER_UPDATE_EVENT_NAME, cb);

      return () => {
        window.removeEventListener(ROUTER_UPDATE_EVENT_NAME, cb);
      };
    }, [cb]);
  };
