import React from 'react';

import { withErrorBoundary } from 'common/components/ErrorBoundary/withErrorBoundary';
import { RCM_BLOCK_TYPE } from 'common/hooks/useRcm';

import { RcmWidget } from '../..';
import { WidgetLayoutTypeV2, WidgetTemplate } from '../../typings';

// скрываем виджет реккомендов, пока пользователь не доскроллит до последней новости
// https://jira.rambler-co.ru/browse/NEWS-10941
const LAST_FEED_INDEX = 4;

export type RcmWidgetClusterFeedPropsType = {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
  rcmBlockType: RCM_BLOCK_TYPE;
  clusterId: ClusterData['id'] | null;
  lastFeedIndex: number;
};

/**
 * Обертка для рендера виджета реккомендов в бесконечке.
 * @param children - дочерние компоненты для отрисовки;
 * @param className - класс, если нужна обертка вокруг виджета;
 * @param rcmBlockType - алиас, под которым хранится blockId в сторе;
 * @param clusterId - id кластера, для которого получаем рекомменды (дб последним из feedList);
 * @param lastFeedIndex - индекс последнего элемента в бесконечке, может быть разным в зависимости от параметра clusterFeedDebug.
 */
export const RcmWidgetClusterFeed = withErrorBoundary(
  function RcmWidgetClusterFeed({
    children,
    className,
    rcmBlockType,
    clusterId,
    lastFeedIndex = LAST_FEED_INDEX,
  }: RcmWidgetClusterFeedPropsType) {
    return (
      <>
        {children}
        {/* скрываем виджет реккомендов, пока пользователь не доскроллит до последней новости
        https://jira.rambler-co.ru/browse/NEWS-10941 */}
        {!!(children as React.ReactNode[])?.[lastFeedIndex] && !!clusterId && (
          <RcmWidget
            className={className}
            rcmBlockType={rcmBlockType}
            templateName={WidgetTemplate.v2}
            layoutType={WidgetLayoutTypeV2.Vertical}
            clusterId={clusterId}
          />
        )}
      </>
    );
  },
);
