import cn from 'classnames';
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  memo,
  useId,
  useMemo,
} from 'react';

import { useScroll } from 'common/hooks/useScrollResize';
import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';
import {
  selectIsHumanCenteredStrategyStateActivated,
  selectIsMobile,
} from 'common/redux/runtime/selectors';
import { COPY_COLOR } from 'config/constants/css';
import { useClickOutside } from 'desktop/hooks/useClickOutside';
import { ShareIcon, ShareIosIcon } from 'icons';
import { getShareImageUrl } from 'server/shareImageService/getShareImageUrl';
import { useAppSelector } from 'store/hooks';
import { initShareButtons } from 'utils/initShareButtons';

const SOCIAL_BUTTONS_STYLES: RamblerShareWidgetStylesType = {
  buttonHeight: 40,
  buttonHorizontalMargin: 10,
  iconSize: 20,
  borderRadius: 50,
  font: 'Manrope, Roboto',
};

const SOCIALS_ORDER = [
  'vkontakte',
  'telegram',
  'odnoklassniki',
  'whatsapp',
  'livejournal',
  'copy',
];

const STYLES_FOR_BUTTON = {
  copy: { buttonBackground: COPY_COLOR, buttonBackgroundHover: 'inherit' },
};

export enum CLUSTER_BUTTONS_PLACES {
  top = 'top',
  bottom = 'bottom',
}

export type ShareButtonPropsType = {
  clusterUrl: string;
  clusterId: ClusterData['id'];
  clusterTitle: ClusterData['title'];
  clusterImageUrl?: ClusterData['image']['url'];
  dataRamblerShare: string;
  componentPlace: CLUSTER_BUTTONS_PLACES;
  customStyles?: StylesType;
};

/**
 * Кнопка с дропдауном соцсетей для шаринга
 * @param props.clusterUrl - url кластера
 * @param props.clusterTitle - заголовок кластера
 * @param props.clusterId - id кластера
 * @param props.clusterImageUrl - урл картинки кластера
 * @param props.dataRamblerShare - разметка топ-100
 * @param props.componentPlace - место вызова компонента
 * @param props.customStyles - объект стилизации компонента
 */
export const ShareButton = memo(
  ({
    clusterTitle,
    clusterId,
    clusterUrl,
    dataRamblerShare,
    componentPlace,
    customStyles = {},
  }: ShareButtonPropsType) => {
    const forceRedesign = useAppSelector(
      selectIsHumanCenteredStrategyStateActivated,
    );
    const isMobile = useAppSelector(selectIsMobile);

    const socialId = useId();
    const [isVisible, setIsVisible] = useState(false);
    const top100Attribute = useTop100AttributeWithValue('button');
    const shareButtonsWrapperRef = useRef<HTMLDivElement>(null);
    const items = shareButtonsWrapperRef.current?.childNodes
      ? [...shareButtonsWrapperRef.current.childNodes].map((node) => {
          const element = node as HTMLDivElement;

          return element.getAttribute('data-rambler-share');
        })
      : [];
    const dropdownRef = useClickOutside<HTMLDivElement>(
      () => setIsVisible(false),
      isVisible,
    );
    const [currentItem, setCurrentItem] = useState<string | null>(null);

    const firstItem = items[0];
    const lastItem = items[items.length - 1];

    /** Скрываем dropdown-меню при скролле */
    useScroll(isVisible ? () => setIsVisible(false) : null);

    const shareImageUrl = useMemo(
      () =>
        getShareImageUrl({
          clusterId,
        }),
      [clusterId],
    );

    const handleFocus = useCallback((event: Event) => {
      const eventTarget = event.target as HTMLDivElement;
      const dataAttribute = eventTarget.getAttribute('data-rambler-share');

      setCurrentItem(dataAttribute);
    }, []);

    const handleKeyDown = useCallback(
      (event: KeyboardEvent) => {
        if (!currentItem && event.shiftKey && event.code === 'Tab') {
          setIsVisible(false);
          setCurrentItem(null);

          return;
        }

        const isHide =
          (currentItem === firstItem &&
            event.shiftKey &&
            event.code === 'Tab') ||
          (currentItem === lastItem && !event.shiftKey && event.code === 'Tab');

        if (isHide) {
          setIsVisible(false);
          setCurrentItem(null);
        }
      },
      [setIsVisible, currentItem, firstItem, lastItem],
    );

    const handleClick = useCallback(
      () => setIsVisible(!isVisible),
      [isVisible],
    );

    useEffect(() => {
      const buttonsNode = shareButtonsWrapperRef?.current;

      initShareButtons({
        buttonsNode,
        handleFocus,
        clusterUrl,
        clusterTitle,
        styles: SOCIAL_BUTTONS_STYLES,
        customOrder: SOCIALS_ORDER,
        shareImageUrl,
        stylesForButton: STYLES_FOR_BUTTON,
      });

      return () => {
        buttonsNode?.childNodes.forEach((element) => {
          const child = element as HTMLDivElement;

          child.removeEventListener('focus', handleFocus);
        });
      };
    }, [clusterUrl, clusterTitle, shareImageUrl, handleFocus]);

    useEffect(() => {
      window.addEventListener('keydown', handleKeyDown);

      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }, [handleKeyDown]);

    return (
      <div
        className={customStyles.shareWrapper}
        ref={dropdownRef}
        data-rambler-share={`${dataRamblerShare}::${componentPlace}_smm_block`}
      >
        <button
          type="button"
          className={cn(
            customStyles.shareBtn,
            isVisible && customStyles.shareBtn_active,
          )}
          onClick={handleClick}
          aria-label="Поделиться новостью"
          aria-haspopup="true"
          aria-expanded={isVisible}
          aria-controls={socialId}
          {...top100Attribute}
        >
          <ShareIcon className={customStyles.iconShare} />
          <ShareIosIcon
            className={cn(customStyles.iconShare, customStyles.ios)}
          />
        </button>
        <div
          id={socialId}
          className={cn(
            'rambler-share',
            customStyles.shareDropdown,
            isVisible && customStyles.shareDropdown_open,
            forceRedesign && customStyles.shareDropdownRedesign,
            isMobile && customStyles.mobile,
          )}
          aria-hidden={!isVisible}
          ref={shareButtonsWrapperRef}
        />
      </div>
    );
  },
);
