import { BlockElementPropsType } from '@media-components/draft-parser';
import React, { memo } from 'react';

import { useDraftContext } from 'common/components/ClusterContentOnDraft/context';
import { ArticleMediaSectionMobileDataType } from 'common/components/ClusterContentOnDraft/draftModifier/generators/articleMediaSectionBlockMobileGenerator';
import { MediaSection } from 'mobile/components/MediaSection';

/**
 * Компонент рендера блока драфта для медиа секции мобильной версии.
 */
export const ArticleMediaSectionBlockMobile = memo(
  ({
    data,
    styles,
  }: BlockElementPropsType<ArticleMediaSectionMobileDataType, unknown>) => {
    const { pageId, puids } = useDraftContext();

    if (Number.isNaN(Number(data?.[0]?.clusterFeedListIndex)) || !pageId) {
      return null;
    }

    const { clusterFeedListIndex } = data![0];

    return (
      <div className={styles?.root}>
        <MediaSection
          clusterIndex={clusterFeedListIndex}
          clusterId={pageId}
          puids={puids}
        />
      </div>
    );
  },
  (prev, next) =>
    prev?.data?.[0]?.clusterFeedListIndex ===
    next?.data?.[0]?.clusterFeedListIndex,
);
