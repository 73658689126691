import { InjectorType } from '@media-components/draft-parser';

import { relatedBlockGenerator } from 'common/components/ClusterContentOnDraft/draftModifier/generators/relatedBlockGenerator';
import { RNET_BLOCK_ID_FOR_RESOURCES } from 'config/constants/resourceRnet';

type Props = {
  resourceId: ClusterData['resourceId'];
  isMulticluster: boolean;
};

/**
 * Инжектор последних новостей источника если он не из рнет.
 * @param props.blocks - блоки драфта;
 * @param props.isMulticluster - флаг, что это мультикластер.
 */
export const injectRelated: InjectorType<Props> = ({
  blocks,
  resourceId,
  isMulticluster,
}) => {
  const isRnet =
    !!RNET_BLOCK_ID_FOR_RESOURCES[
      resourceId as keyof typeof RNET_BLOCK_ID_FOR_RESOURCES
    ];

  if (isMulticluster || isRnet) {
    return blocks;
  }

  const relatedSection = relatedBlockGenerator();

  return [...blocks, relatedSection];
};
