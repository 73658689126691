import React, { memo } from 'react';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';

import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';
import {
  selectProjectAlias,
  selectProjectName,
} from 'common/redux/runtime/selectors';

type DeferredClusterHeaderPropsType = {
  cluster: ClusterData;
  top100PageType?: string;
  isMobile: boolean;
};

const VERTICALS_WITHOUT_ARCHIVE = [
  'sport',
  'finance',
  'woman',
  'weekend',
  'doctor',
  'travel',
];

/**
 * Метаинформация страницы отложенного кластера
 * @param cluster - данные об этом кластере
 * @param top100PageType - топ100
 */
export const DeferredClusterHeader = memo(
  ({ cluster, top100PageType = '' }: DeferredClusterHeaderPropsType) => {
    const projectAlias = useSelector(selectProjectAlias);
    const projectName = useSelector(selectProjectName);

    const top100Attribute = useTop100AttributeWithValue(top100PageType);

    // нужен, что бы на клиенте у body не изменять класс
    const isSSR = typeof window === 'undefined';
    const { image } = cluster;
    const isArchiveDisabled = VERTICALS_WITHOUT_ARCHIVE.includes(projectAlias);
    const robots = isArchiveDisabled ? 'noyaca,noarchive' : 'noyaca';

    return (
      <Helmet>
        {isSSR && <body className="no-js" {...top100Attribute} />}
        <meta name="robots" content={robots} />
        <title>
          {cluster.longTitle
            ? `${cluster.longTitle} — ${projectName}`
            : projectName}
        </title>
        <link rel="preload" href={image.url} as="image" />
      </Helmet>
    );
  },
);
