import cn from 'classnames';
import React, { memo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { RamblerImage } from 'common/components/RamblerComponents/RamblerImage';
import { ClusterImageObject } from 'common/components/schemaOrg/ImageObject/ClusterImageObject';
import { useImageHeight } from 'common/hooks/useImageHeight';
import {
  selectDomainConfig,
  selectIsHumanCenteredStrategyStateActivated,
  selectProjectAlias,
  selectProjectId,
} from 'common/redux/runtime/selectors';
import {
  IMAGE_HEIGHT,
  IMAGE_HEIGHT_COMPACT,
  WINDOW_WIDTH_COMPACT,
} from 'config/constants/css';
import { domainGenerator } from 'utils/urlGenerator';

import { ImageDescription } from './components/ImageDescription';

import s from './styles.module.css';

type ImagePropsType = {
  cluster: ClusterData;
  textScaleValue?: number;
  withPhoto?: boolean;
  styles?: StylesType;
  imageWidth?: number;
  imageHeight?: number;
  noImage?: boolean;
  withDescription?: boolean;
  cropLength?: number;
};

/**
 * Главное фото кластера и ссылка на его источник
 * @param cluster - объект данных кластера
 * @param textScaleValue - значение масштабирования текста
 * @param withPhoto - нужна ли подпись "Фото"
 * @param styles - стили
 * @param imageHeight - высота изображения для кропа
 * @param noImage - не отображать картинку. Для кейса на dir1
 * @param withDescription - флаг отображения всей подписи к фото
 * @param cropLength - длина текста для обрезания
 */
export const Image = memo(
  ({
    cluster,
    textScaleValue,
    withPhoto = true,
    styles = s,
    noImage = false,
    withDescription = true,
    cropLength,
  }: ImagePropsType) => {
    const domainConfig = useSelector(selectDomainConfig, shallowEqual);
    const projectId = useSelector(selectProjectId);
    const projectAlias = useSelector(selectProjectAlias);
    const forceRedesign = useSelector(
      selectIsHumanCenteredStrategyStateActivated,
    );

    const { image, title } = cluster;

    const domain = domainGenerator(domainConfig, projectId);
    const imageUrl = image?.url || `${domain}/special/no_img.jpg`;
    const imageHeight = useImageHeight(
      WINDOW_WIDTH_COMPACT,
      IMAGE_HEIGHT,
      IMAGE_HEIGHT_COMPACT,
    );

    const schemaOrg = <ClusterImageObject cluster={cluster} />;

    return noImage ? (
      schemaOrg
    ) : (
      <figure className={styles.root}>
        {schemaOrg}
        {image && (
          <RamblerImage
            height={imageHeight}
            src={imageUrl}
            alt={image.description || title}
            isS3={image.s3}
            className={cn(styles.img, forceRedesign && styles.redesign)}
            errorClassName={styles.imgError}
          />
        )}
        {withDescription && (
          <ImageDescription
            projectAlias={projectAlias}
            textScaleValue={textScaleValue}
            withPhoto={withPhoto}
            clusterImage={cluster.image}
            clusterResourceId={cluster.resourceId}
            styles={styles}
            cropLength={cropLength}
          />
        )}
      </figure>
    );
  },
);
