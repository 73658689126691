import { BlockElementPropsType } from '@media-components/draft-parser';
import React, { memo } from 'react';

import { ResourceRelatedBlockDataType } from 'common/components/ClusterContentOnDraft/draftModifier/generators/resourceRelatedBlockGenerator';
import { ResourceRelated as ResourceRelatedComponent } from 'mobile/components/ResourceRelated';

/**
 * Компонент с последними новостями рнет источника.
 * @param data.resourceId – id источника
 * @param styles – стили блока
 */
export const ResourceRelated = memo(
  ({
    data,
    styles,
  }: BlockElementPropsType<ResourceRelatedBlockDataType, unknown>) => {
    if (Number.isNaN(Number(data?.[0]?.resourceId))) {
      return null;
    }

    const { clusterId, resourceId } = data![0]!;

    return (
      <div className={styles?.root}>
        <ResourceRelatedComponent
          clusterId={clusterId}
          resourceId={resourceId!}
        />
      </div>
    );
  },
  (prev, next) => prev?.data?.[0]?.resourceId === next?.data?.[0]?.resourceId,
);
