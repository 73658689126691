import cn from 'classnames';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { RamblerImage } from 'common/components/RamblerComponents/RamblerImage';
import { useGetImageSrc } from 'common/components/schemaOrg/hooks';
import { SlideImageObject } from 'common/components/schemaOrg/ImageObject/SlideImageObject';
import { useImageHeight } from 'common/hooks/useImageHeight';
import {
  selectCurrentServerTime,
  selectIsHumanCenteredStrategyStateActivated,
} from 'common/redux/runtime/selectors';
import {
  IMAGE_HEIGHT_SLIDE,
  IMAGE_HEIGHT_SLIDE_COMPACT,
  WINDOW_WIDTH_COMPACT,
} from 'config/constants/css';

import { Caption } from './Caption';

type CarouselSlidePropsType = {
  slide: ClusterGallerySlideType;
  slideIndex: number;
  cluster: ClusterData;
  styles: StylesType;
};

/**
 * Слайд галереи для десктопа.
 * @param slide - слайд;
 * @param slideIndex - индекс слайда;
 * @param cluster - кластер;
 * @param styles - стили компонента.
 */
export const CarouselSlide = memo((props: CarouselSlidePropsType) => {
  const forceRedesign = useSelector(
    selectIsHumanCenteredStrategyStateActivated,
  );
  const { slide, slideIndex, cluster, styles } = props;

  const { id, url, description = '', source, s3 } = slide;
  const { title: sourceTitle } = source;

  const {
    image: clusterMainImage,
    longTitle: clusterLongTitle,
    publicationTime: clusterPublicationTime,
  } = cluster;

  const currentServerTime = useSelector(selectCurrentServerTime);

  const isGifImage = url.slice(-3) === 'gif';
  const isMainSlide = id === clusterMainImage?.id;

  const isDeferredCluster =
    !!clusterPublicationTime &&
    new Date(clusterPublicationTime) > new Date(currentServerTime);

  const imageHeight = useImageHeight(
    WINDOW_WIDTH_COMPACT,
    IMAGE_HEIGHT_SLIDE,
    IMAGE_HEIGHT_SLIDE_COMPACT,
  );

  const imageUrls = useGetImageSrc(url, s3);
  const schemaImages = isGifImage ? [url, url, url] : imageUrls;

  const caption = (
    <Caption
      slide={slide}
      slideIndex={slideIndex}
      cluster={cluster}
      styles={styles}
    />
  );

  return (
    <>
      {!isDeferredCluster && (
        <SlideImageObject
          imgUrls={schemaImages}
          name={`${clusterLongTitle} фото ${slideIndex}`}
          description={description}
          author={sourceTitle}
          isMain={isMainSlide}
        />
      )}
      <div className={styles.root}>
        <div
          className={cn(
            styles.imageWrapper,
            forceRedesign && styles.imageWrapperRedesign,
          )}
        >
          <RamblerImage
            className={cn(styles.image, 'slideImage')}
            src={url}
            height={imageHeight}
            alt={description}
            isS3={s3}
            isLazy
          />
        </div>
        {caption}
      </div>
    </>
  );
});
