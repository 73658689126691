import React, { memo } from 'react';

import { CarouselMobile } from 'common/components/Carousel/CarouselMobile';
import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';

import s from './styles.module.css';

type ClusterGalleryPropsType = {
  cluster: ClusterData;
  clusterIndex: number;
};

/**
 * Галерея кластера с описанием и ссылками на источники фото
 * @param cluster - кластер
 * @param clusterIndex - индекс кластера
 */
export const ClusterGallery = memo((props: ClusterGalleryPropsType) => {
  const { cluster, clusterIndex } = props;
  const top100Attribute = useTop100AttributeWithValue('gallery');

  return (
    <figure className={s.gallery} {...top100Attribute}>
      <CarouselMobile cluster={cluster} clusterIndex={clusterIndex} />
    </figure>
  );
});
