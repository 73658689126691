import { createContext, useContext } from 'react';

const HideLinksContext = createContext(false);
export const HideLinksContextProvider = HideLinksContext.Provider;

/**
 * Хук для передачи информации ссылкам внизу, что необходимо отключить ссылки.
 */
export const useHideLinksContext = () => {
  const value = useContext(HideLinksContext);

  return value ?? false;
};
