import { BlockElementPropsType } from '@media-components/draft-parser';
import React, { memo } from 'react';

import { BorderedQuote } from 'common/components/BorderedQuote';

/**
 * Компонент блока цитаты.
 * @param props - параметры компонента;
 * @param props.children - информация о содержимом блока цитаты;
 * @param props.styles - дополнительные стили для компонента.
 */
export const Blockquote = memo(
  ({ children, styles }: BlockElementPropsType<unknown, unknown>) => (
    <BorderedQuote className={styles?.root}>{children}</BorderedQuote>
  ),
);
