import { BLOCK_TYPES, BlockTypes } from '@media-components/draft-parser';

import { CUSTOM_BLOCK_TYPE } from '../typings';

/** Массив блоков, которые считаются отдельными блоками */
const ATOMIC_LIKE_BLOCKS = [
  BLOCK_TYPES.Atomic as BlockTypes,
  CUSTOM_BLOCK_TYPE.ArticleMediaSection,
];

/** Массив блоков, которые считаются едиными блоками и их нельзя разрывать рекламой чтобы не сломать разметку ex. 51758711 */
const WITHOUT_BREAKS_BLOCKS = [
  BLOCK_TYPES.OLItem as BlockTypes,
  BLOCK_TYPES.ULItem as BlockTypes,
];

/**
 * Проверка, что это спецблок.
 * @param type - тип блока.
 */
export const checkIsAtomicLikeBlock = (type: BlockTypes | CUSTOM_BLOCK_TYPE) =>
  ATOMIC_LIKE_BLOCKS.includes(type);

/**
 * Проверка, что это блок может разделяться рекламой.
 * @param type - тип блока.
 */
export const checkCanBreaks = (type: BlockTypes) =>
  !WITHOUT_BREAKS_BLOCKS.includes(type);

/**
 * Проверка на возможность вставки баннера.
 * @param blocks - блоки драфта;
 * @param index - проверяемое месторасположение для вставки.
 */
export const checkCanInsertBanners = (
  blocks: RawDraftContentState['blocks'],
  index: number,
): boolean => {
  const isNearAtomicParagraph =
    checkIsAtomicLikeBlock(
      blocks[index].type as BlockTypes | CUSTOM_BLOCK_TYPE,
    ) ||
    checkIsAtomicLikeBlock(
      blocks[index + 1]?.type as BlockTypes | CUSTOM_BLOCK_TYPE,
    );

  return !isNearAtomicParagraph && index !== blocks.length - 1;
};

/**
 * Проверка на возможность вставки баннера между двумя абзацами.
 * В отличие от десктопной, реклама может вставать рядом с квизом или картинкой, но не должна разрывать списки
 * @param blocks - блоки драфта;
 * @param index - проверяемое месторасположение для вставки.
 */
export const checkCanInsertBetween = (
  blocks: RawDraftContentState['blocks'],
  index: number,
): boolean => {
  const canBreaks =
    checkCanBreaks(blocks[index].type as BlockTypes) &&
    checkCanBreaks(blocks[index + 1]?.type as BlockTypes);

  return canBreaks && index !== blocks.length - 1;
};
