import { textToSpeech } from 'utils/textToSpeech';

import { ChangeSpeak } from './typings';

/**
 * Функция запуска проигрывания переданного заголовка и тела кластера.
 * @param props - объект с данными функции;
 * @param props.clusterId - id проигрываемого кластера;
 * @param props.clusterTitle - заголовок проигрываемого кластера;
 * @param props.clusterBody - тело проигрываемого кластера;
 * @param props.speakingClusterId - id последнего проигранного кластера;
 * @param props.speakingIsPaused - флаг, что проигрывание приостановлено;
 * @param props.onChange - коллбек, принимающий новое состояние проигрыша звука.
 */
export const playSpeech = ({
  clusterId: clusterID,
  clusterTitle,
  clusterBody,
  speakingClusterId: speakingClusterID,
  speakingIsPaused,
  onChange,
}: {
  clusterId: ClusterData['id'];
  clusterTitle: ClusterData['longTitle'];
  clusterBody: ClusterData['body'];
  speakingClusterId: AppStatePagesClusterType['speaking']['clusterID'];
  speakingIsPaused: AppStatePagesClusterType['speaking']['pause'];
  onChange: ChangeSpeak;
}) => {
  const callback = () =>
    onChange({
      play: false,
      pause: false,
      clusterID,
    });

  onChange({
    play: true,
    pause: false,
    clusterID,
  });

  if (clusterID === speakingClusterID && speakingIsPaused) {
    textToSpeech.resumeSpeech(callback);

    return;
  }

  if (clusterID !== speakingClusterID) {
    textToSpeech.stopSpeech();
  }

  textToSpeech.addClusterTextToSpeech(clusterTitle, clusterBody);
  textToSpeech.startSpeech(callback);
};

/**
 * Функция остановки проигрывания звука для переданного кластера.
 * @param props - объект с данными функции;
 * @param props.onChange - коллбек, принимающий новое состояние проигрыша звука.
 */
export const pauseSpeech = (onChange: ChangeSpeak) => {
  textToSpeech.pauseSpeech();
  onChange({
    play: false,
    pause: true,
  });
};
