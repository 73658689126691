import React, { memo } from 'react';

import { RelatedDir1 as RelatedDir1Compnent } from 'common/components/RelatedDir1';

import s from './styles.module.css';

type RelatedDir1PropsType = {
  clusterId: CardData['id'];
};

/**
 * Обертка для общего компонента
 * последние новости
 * @param clusterID - id кластера
 */
export const RelatedDir1 = memo(({ clusterId }: RelatedDir1PropsType) => (
  <RelatedDir1Compnent
    clusterId={clusterId}
    styles={s}
    top100="partner_block::text_widget"
  />
));
