import React, { memo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { selectClusterById } from 'common/redux/commonData/entries/selectors';
import {
  selectCurrentServerTime,
  selectIsMobile,
} from 'common/redux/runtime/selectors';

import { Header as CommonHeader } from './ClusterHeader';
import { DeferredClusterHeader } from './DeferredClusterHeader';

type HeaderPropsType = {
  clusterId: CardData['id'];
};

/**
 * Компонент хедера с метаинформацией для кластера.
 */
export const Header = memo(({ clusterId }: HeaderPropsType) => {
  const currentCluster = useSelector(
    selectClusterById(clusterId),
    shallowEqual,
  );
  const currentServerTime = useSelector(selectCurrentServerTime);
  const isMobile = useSelector(selectIsMobile);

  if (!currentCluster) return null;

  const isDeferredCluster =
    !!currentCluster?.publicationTime &&
    new Date(currentCluster.publicationTime) > new Date(currentServerTime);

  const HeaderComponent = isDeferredCluster
    ? DeferredClusterHeader
    : CommonHeader;

  return (
    <HeaderComponent
      cluster={currentCluster}
      top100PageType="cluster"
      isMobile={isMobile}
    />
  );
});
