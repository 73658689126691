import NukaCarousel from 'nuka-carousel';
import React, { memo, useCallback, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { selectClusterPageTextScale } from 'common/redux/pages/cluster/selectors';
import { selectRuntime } from 'common/redux/runtime/selectors';

import { getInitialIndex, getSlidesConfigListMobile } from '../helpers';
import { useSwipeMobile } from '../hooks';

const nukaCarouselConfig = {
  speed: 200,
  withoutControls: true,
};

type CarouselMobilePropsType = {
  cluster: ClusterData;
  clusterIndex: number;
};

/**
 * Мобильная карусель.
 * @param cluster - кластер;
 * @param clusterIndex - индекс кластера.
 */
export const CarouselMobile = memo((props: CarouselMobilePropsType) => {
  const { cluster, clusterIndex } = props;

  const runtime = useSelector(selectRuntime, shallowEqual);
  const textScaleValue = useSelector(selectClusterPageTextScale);

  // Генерация слайдов для карусели
  const slidesConfigList = getSlidesConfigListMobile({
    cluster,
    textScaleValue,
  });

  // Вычисление начального слайда для отображения по хэшу в урле
  const initialIndex = getInitialIndex({ clusterIndex, slidesConfigList });

  const [slideIndex, setSlideIndex] = useState<number>(initialIndex);

  // Получение обработчика свайпа
  const { onSwiped } = useSwipeMobile({
    runtime,
    slidesConfigList,
    setSlideIndex,
  });

  const afterSlide = useCallback(
    (index: number) => {
      if (slideIndex !== index) {
        onSwiped({ slideIndex: index });
      }
    },
    [slideIndex, onSwiped],
  );

  return (
    <NukaCarousel
      slideIndex={slideIndex}
      afterSlide={afterSlide}
      {...nukaCarouselConfig}
    >
      {slidesConfigList.map(({ component }) => component)}
    </NukaCarousel>
  );
});
