import cn from 'classnames';
import React, { memo } from 'react';

import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';
import { defaultCallback } from 'config/constants/common';

import s from './styles.module.css';

type SourceInfoPropsType = {
  utm: string;
  resource: ClusterResourceType;
  urlMoreLink: string;
  moreSourceCount: number;
  styles?: StylesType;
  onClick?: () => void;
};

/**
 * Карточка источника
 * @param resource - объект источников кластера
 * @param utm - utm метки
 * @param urlMoreLink - ссылка которая ведет на список источников
 * @param moreSourceCount - количество источников которое будет отображаться в "+ еще ${moreSourceCount}"
 * @param topic - топик кластера
 * @param styles - стили
 * @param onClick - колбэк для ссылки
 */
export const SourceInfo = memo(
  ({
    utm,
    urlMoreLink,
    moreSourceCount,
    resource: { title, url },
    styles = {},
    onClick = defaultCallback,
  }: SourceInfoPropsType) => {
    const top100AttributeMainSource = useTop100AttributeWithValue('source');
    const top100AttributeMoreSource =
      useTop100AttributeWithValue('more_sources');

    return (
      <div className={cn(s.root, styles.sourceRoot)}>
        <a
          rel="nofollow noopener noreferrer"
          aria-label={`Перейти на страницу источника ${title}`}
          target="_blank"
          href={url ? `${url}?${utm}` : ''}
          className={cn(s.title, styles.sourceTitle)}
          data-test="source-link"
          onClick={onClick}
          {...top100AttributeMainSource}
        >
          {title}
        </a>

        {moreSourceCount > 0 && (
          <>
            и
            <a
              className={cn(s.more, styles.sourceCount)}
              aria-label="Перейти к списку новостей по теме от всех источников"
              rel="nofollow noopener noreferrer"
              target="_blank"
              href={urlMoreLink}
              {...top100AttributeMoreSource}
            >
              {`ещё ${moreSourceCount}`}
            </a>
          </>
        )}
      </div>
    );
  },
);
