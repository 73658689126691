import { blockGenerator } from '@media-components/draft-parser';
import _uniqueid from 'lodash.uniqueid';

import { CUSTOM_BLOCK_TYPE } from 'common/components/ClusterContentOnDraft/typings';

/**
 * Блок последних новостей источника не из рнет.
 */
export const relatedBlockGenerator = () =>
  blockGenerator({
    key: `${CUSTOM_BLOCK_TYPE.Related}_${_uniqueid()}`,
    type: CUSTOM_BLOCK_TYPE.Related,
  });
