import { EntityPropsType } from '@media-components/draft-parser';
import React, { memo } from 'react';

import { useDraftContext } from 'common/components/ClusterContentOnDraft/context';
import { ErrorBoundary } from 'common/components/ErrorBoundary';
import { MetaMaxVideoPreview } from 'common/components/MetaMaxVideoPreview';
import { VideoPlayerBody } from 'common/components/VideoPlayer/components/VideoPlayerBody';
import { useCurrentCluster } from 'common/hooks/useCurrentCluster';
import { getPuidsStrForEaglePlatform } from 'common/utils/searchIframe';

export type VideoPlatformDataPropsType = {
  id: string;
  width: string | undefined;
  height: string | undefined;
  filterId: string | undefined;
  playerTemplateId: string | undefined;
};

/**
 * Компонент видеовставки платформы.
 * https://confluence.rambler-co.ru/pages/viewpage.action?pageId=73974440
 * @param children - компонент содержимого энтити;
 * @param data.id - id видео;
 * @param data.width - ширина блока с видео;
 * @param data.height - высота блока с видео;
 * @param data.filterId - (внутренний параметр) id, отвечающее за выводимые в рекомендациях плеера видео;
 * @param data.playerTemplateId - (внутренний параметр) id шаблона плеера (с рекомендациями, без и прочие);
 * @param isBroadcast - кастомный флаг, что это прямая трансляция.
 */
export const VideoPlatform = memo(
  ({
    data,
    draftKey,
    isBroadcast = false,
  }: EntityPropsType<VideoPlatformDataPropsType, unknown> & {
    isBroadcast?: boolean;
  }) => {
    const playerId = `videoPlayer-${data.id}-${draftKey}`;
    const { puids, pageId, pageUrl } = useDraftContext();
    const { isCurrentCluster } = useCurrentCluster(pageId);

    if (!data?.id) {
      return null;
    }

    const { id: recordId, filterId, playerTemplateId, height } = data;

    const sspJparams = getPuidsStrForEaglePlatform({
      puids,
      newPlayer: true,
    });

    return (
      <>
        <MetaMaxVideoPreview />
        <ErrorBoundary componentName="VideoPlayerBody">
          <VideoPlayerBody
            clusterUrl={pageUrl}
            recordId={recordId}
            playerTemplateId={playerTemplateId}
            multiplayerFilterId={filterId}
            playerId={playerId}
            height={Number(height)}
            sspJparams={sspJparams}
            isLive={isBroadcast}
            isCurrentCluster={isCurrentCluster}
          />
        </ErrorBoundary>
      </>
    );
  },
  (prev, next) =>
    prev.data.id === next.data.id &&
    prev.data.playerTemplateId === next.data.playerTemplateId &&
    prev.data.filterId === next.data.filterId,
);

/**
 * Небольшая обертка для видеотрансляций.
 */
export const VideoPlatformLive = memo(
  ({
    children,
    data,
    draftKey,
    outerProps,
  }: EntityPropsType<VideoPlatformDataPropsType, unknown>) => (
    <VideoPlatform
      data={data}
      draftKey={draftKey}
      isBroadcast
      outerProps={outerProps}
    >
      {children}
    </VideoPlatform>
  ),
);
