import cn from 'classnames';
import React, { memo } from 'react';

import { Link } from 'common/components/Link';
import { RamblerImage } from 'common/components/RamblerComponents/RamblerImage';
import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';
import { defaultCallback } from 'config/constants/common';

import s from './styles.module.css';

const IMAGE_HEIGHT = 25;

type EditorInfoPropsType = {
  utm: string;
  editor: FullEditorType;
  withIcon: boolean;
  customStyles?: StylesType;
  onClick?: () => void;
};

/**
 * Карточка редактора
 * @param editor - инфа по редактору
 * @param utm - utm метки
 * @param withIcon - нужно ли показывать иконку автора
 * @param customStyles - стили для amp версии
 */
export const EditorInfo = memo(
  ({
    utm,
    withIcon,
    editor: { alias: editorAlias, image, name: editorName },
    customStyles = {},
    onClick = defaultCallback,
  }: EditorInfoPropsType) => {
    const top100Attribute = useTop100AttributeWithValue('editor');

    return (
      <div className={cn(s.root, customStyles.editorRoot)} {...top100Attribute}>
        {withIcon && image?.url && (
          <RamblerImage
            height={IMAGE_HEIGHT}
            src={image.url}
            alt={editorName}
            className={cn(s.avatar, customStyles.editorAvatar)}
            isS3={image.s3}
            data-test="editor-info-image"
            isLazy
          />
        )}
        <Link
          ariaLabel={`Открыть информацию о редакторе ${editorName}`}
          href={`/editors/${editorAlias}/?${utm}`}
          force
          className={cn(s.name, customStyles.editorName)}
          data-test="editor-info-link"
          onClick={onClick}
        >
          {editorName}
        </Link>
      </div>
    );
  },
);
