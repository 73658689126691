import cn from 'classnames';
import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { getCounter } from 'common/components/Carousel/helpers';
import { RamblerImage } from 'common/components/RamblerComponents/RamblerImage';
import { useGetImageSrc } from 'common/components/schemaOrg/hooks';
import { SlideImageObject } from 'common/components/schemaOrg/ImageObject/SlideImageObject';
import { TextScaleValueType } from 'common/redux/pages/cluster';
import { selectCurrentServerTime } from 'common/redux/runtime/selectors';
import { cropImage } from 'common/utils/crop';

import s from './styles.module.css';

const IMAGE_HEIGHT = 300;

type CarouselSlidePropsType = {
  slide: ClusterGallerySlideType;
  slideIndex: number;
  cluster: ClusterData;
  textScaleValue: TextScaleValueType;
};

/**
 * Слайд галереи для мобилы.
 * @param slide - слайд;
 * @param slideIndex - индекс слайда;
 * @param cluster - кластер;
 * @param textScaleValue - значение маштабируемости текста.
 */
export const CarouselSlide = memo((props: CarouselSlidePropsType) => {
  const { slide, slideIndex, cluster, textScaleValue } = props;

  const { id, url, description = '', source, width, height, s3 } = slide;

  const {
    gallery = [],
    title: clusterTitle,
    longTitle,
    image: clusterMainImage,
    publicationTime,
  } = cluster;

  const location = useLocation();

  const currentServerTime = useSelector(selectCurrentServerTime);

  const [isActiveSlide, setIsActiveSlide] = useState<boolean>(true);

  const counter = getCounter({ slideIndex, galleryLength: gallery.length });

  const isGifImage = url.slice(-3) === 'gif';
  const isMainSlide = id === clusterMainImage?.id;

  const isDeferredCluster =
    !!publicationTime &&
    new Date(publicationTime) > new Date(currentServerTime);

  const imageRatio = width && height ? width / height : -1;
  const isVerticalImage = imageRatio < 1.05;
  const cropString = isVerticalImage ? `${350}x${448}i` : `${448}x${350}i`;
  const imgSrc = isGifImage || s3 ? url : cropImage(url, cropString);

  const imageUrls = useGetImageSrc(url, s3);
  const schemaImages = isGifImage ? [url, url, url] : imageUrls;

  useEffect(() => {
    const hashIndex = location.hash.slice(1);

    if (hashIndex === `${slideIndex}` || hashIndex === '') {
      setIsActiveSlide(true);
    } else {
      setIsActiveSlide(false);
    }
  }, [slideIndex, location]);

  return (
    <figure className={s.slide} data-hash={slideIndex}>
      {!isDeferredCluster && (
        <SlideImageObject
          imgUrls={schemaImages}
          name={`${longTitle} фото ${slideIndex}`}
          description={description}
          author={source.title}
          isMain={isMainSlide}
        />
      )}
      <div
        className={cn(s.imageWrapper, {
          [s['imageWrapper--vertical']]: isVerticalImage,
        })}
      >
        <RamblerImage
          className={s.image}
          src={imgSrc}
          height={IMAGE_HEIGHT}
          alt={`${clusterTitle} фото ${slideIndex}`}
          isS3={s3}
          isLazy
        />
      </div>
      <figcaption
        className={cn(s.caption, { [s['caption--active']]: isActiveSlide })}
      >
        <p className={cn(s.description, s[`description_${textScaleValue}`])}>
          <span className={cn(s.counter, s[`counter_${textScaleValue}`])}>
            {counter}
          </span>
          &nbsp;
          {description}
        </p>
      </figcaption>
    </figure>
  );
});
