import DraftParser, {
  CustomRendererMapType,
  EntityRendererMapType,
  InlineRendererMapType,
} from '@media-components/draft-parser';
import cn from 'classnames';
import React, { memo, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { usePublisherAdData } from 'common/hooks/usePublishedAdData';
import {
  selectClusterById,
  selectClusterResource,
} from 'common/redux/commonData/entries/selectors';
import {
  selectClusterPageClusterIdByIndex,
  selectClusterPageTextScale,
} from 'common/redux/pages/cluster/selectors';
import {
  selectIsHumanCenteredStrategyStateActivated,
  selectIsMobile,
} from 'common/redux/runtime/selectors';
import { CLUSTER_TYPE } from 'config/constants/cluster';

import { DraftContextProvider } from './context';
import { useModifyDraft } from './hooks';
import { getRender } from './renderer';
import { CustomBlockRendererMapType } from './typings';

import s from './styles.module.css';

/**
 * Проект пока не готов к SPA
 * Как починится - можно убрать этот флаг.
 */
const SHOULD_DISABLE_SPA = false;

type ClusterContentOnDraftPropsType = {
  draft: RawDraftContentState;
  clusterFeedListIndex: number;
  isMulticluster?: boolean;
};

type CustomRendererType = CustomRendererMapType<
  InlineRendererMapType,
  CustomBlockRendererMapType,
  EntityRendererMapType
>;

/**
 * Компонент контента кластера с использованием драфта для десктопа.
 * @param draft - объект драфта кластера;
 * @param clusterFeedListIndex - индекс кластера в фид листе;
 * @param isMulticluster - флаг, что это мультикластер.
 */
export const ClusterContentOnDraft = memo(
  ({
    draft,
    clusterFeedListIndex,
    isMulticluster = false,
  }: ClusterContentOnDraftPropsType) => {
    const textScaleValue = useSelector(selectClusterPageTextScale);
    const isMobile = useSelector(selectIsMobile);
    const clusterId = useSelector(
      selectClusterPageClusterIdByIndex(clusterFeedListIndex),
    );
    const cluster = useSelector(selectClusterById(clusterId), shallowEqual);
    const resource = useSelector(
      selectClusterResource(clusterId),
      shallowEqual,
    );
    const forceRedesign = useSelector(
      selectIsHumanCenteredStrategyStateActivated,
    );

    const { publisherId, publisherScope } = usePublisherAdData();

    const { type, puids, gallery, url, isPaid } = cluster ?? {};

    const modifiedDraft = useModifyDraft({
      draft,
      clusterId,
      clusterType: type,
      clusterFeedListIndex,
      resourceId: resource?.id,
      clusterHasGallery: type === CLUSTER_TYPE.photo && !!gallery,
      isMulticluster,
      isMobile,
      isPaid,
      forceRedesign,
    });

    const outerProps = useMemo(
      () => ({
        puids,
        pageUrl: url || '',
        pageId: clusterId,
        forceLegacy: __DEV__,
        disableSPA: SHOULD_DISABLE_SPA,
        isMobile,
      }),
      [clusterId, isMobile, puids, url],
    );

    const customRenderer = getRender({
      forceRedesign,
      isMulticluster,
      isMobile,
      outerProps,
    });

    return (
      <div
        className={cn(s.root, `fontSize_${textScaleValue}`, {
          [s.multicluster]: isMulticluster,
          [s.mobile]: isMobile,
        })}
      >
        <DraftContextProvider value={outerProps}>
          <DraftParser
            draft={modifiedDraft}
            customRenderer={customRenderer as CustomRendererType}
            pageId={clusterId}
            pageUrl={url || ''}
            puids={puids}
            forceLegacy={false}
            isMobile={isMobile}
            sspUserId={publisherId}
            sspUserIdScope={publisherScope}
          />
        </DraftContextProvider>
      </div>
    );
  },
);
