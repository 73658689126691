import { BlockLengthType, InjectorType } from '@media-components/draft-parser';
import cn from 'classnames';

import { checkCanInsertBetween } from 'common/components/ClusterContentOnDraft/draftModifier/utils';
import Banners from 'config/constants/banner/banners';
import { checkIsAdlessMaterial } from 'utils/checkIsPartnerMaterial';

import { bannerBlockGenerator } from '../../generators/bannerBlockGenerator';

import s from './styles.module.css';

/** Количество символов, после которых в конце параграфов вставляется баннер */
const BANNER_INSERT_CHAR_COUNT = 500;

const BANNER_NAMES_BY_INDEX: Record<number, Banners> = {
  1: Banners.Content1,
  2: Banners.Inpage,
  3: Banners.ContentSpec,
  4: Banners.Content2,
  5: Banners.Content3,
};

const CLASSNAMES_BY_BANNER_NAME: Partial<Record<Banners, string>> = {
  [Banners.ContentSpec]: 'banner--mobile-spec',
  [Banners.Content2]: 'banner',
  [Banners.Content3]: 'banner',
};

const BANNER_NAME_DEFAULT = Banners.Content4;

type Props = {
  blocksLength: BlockLengthType;
  clusterId: CardData['id'];
  isPaid?: boolean;
};

/**
 * Вживление баннеров Content1, Inpage, ContentSpec, Content2, Content3 между параграфами каждые 500 символов.
 * @param blocks - блоки драфта;
 * @param blockLength - мапка с размерами блоков и колвом текста во всем приложении;
 * @param isPaid - флаг партнерского материала;
 * @param clusterId - id кластера.
 */
export const injectContentBanner: InjectorType<Props> = ({
  blocks,
  isPaid,
  blocksLength,
  clusterId,
}) => {
  const isAdlessMaterial = checkIsAdlessMaterial(clusterId);

  if (isPaid || isAdlessMaterial) {
    return blocks;
  }

  // Вживляем блок каждые BANNER_INSERT_CHAR_COUNT
  const { blocksWithBanners } = blocks.reduce(
    (acc, draftBlock, index) => {
      acc.blocksWithBanners.push(draftBlock);
      acc.charCount += blocksLength.blocks[draftBlock.key]?.relativeLength || 0;

      const isShowBanner =
        acc.charCount > BANNER_INSERT_CHAR_COUNT &&
        !isAdlessMaterial &&
        blocks.length !== index + 1 &&
        checkCanInsertBetween(blocks, index);

      if (isShowBanner) {
        const bannerName =
          BANNER_NAMES_BY_INDEX[acc.bannerIndex] || BANNER_NAME_DEFAULT;

        const banner = bannerBlockGenerator({
          name: BANNER_NAMES_BY_INDEX[acc.bannerIndex] || BANNER_NAME_DEFAULT,
          isLazy: true,
          className: cn(s.banner, CLASSNAMES_BY_BANNER_NAME[bannerName]) || '',
        });

        acc.blocksWithBanners.push(banner);
        acc.bannerIndex += 1;
        acc.charCount = 0;
      }

      return acc;
    },
    {
      blocksWithBanners: [] as RawDraftContentBlock[],
      bannerIndex: 1,
      charCount: 0,
    },
  );

  return blocksWithBanners;
};
