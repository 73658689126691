import { createContext, useContext } from 'react';

import { PuidsType } from 'config/constants/common';

type DraftContextState = {
  pageUrl: ClusterData['url'];
  pageId: ClusterData['id'];
  puids: PuidsType | undefined;
  forceLegacy: boolean;
  disableSPA: boolean;
};

const initialState: DraftContextState = {
  /**
   * Страница, на которой отображается драфт
   */
  pageUrl: '',
  /**
   * Id контента на странице, на которой отображается драфт
   */
  pageId: '',
  /**
   * Данные о пуидах для рекламы
   */
  puids: {},
  /**
   * Флаг, который принудительно рисует легаси энтити.
   * Нужен для отладки
   */
  forceLegacy: false,
  /**
   * Флаг, который отключает действие SPA при клике на ссылки
   */
  disableSPA: false,
};

/**
 * Контекст данных, необходимых для функционирования некоторых энтити.
 */
const DraftContext = createContext(initialState);
export const DraftContextProvider = DraftContext.Provider;

export const useDraftContext = () => {
  const { pageId, pageUrl, puids, forceLegacy, disableSPA } =
    useContext(DraftContext);

  return {
    pageId: pageId ?? initialState.pageId,
    pageUrl: pageUrl ?? initialState.pageUrl,
    puids: puids ?? initialState.puids,
    forceLegacy: forceLegacy ?? initialState.forceLegacy,
    disableSPA: disableSPA ?? initialState.disableSPA,
  };
};
