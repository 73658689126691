import { StateTopicType } from 'common/redux/commonData/topics/typings';
import { TextScaleValueType } from 'common/redux/pages/cluster';
import { PuidsType } from 'config/constants/common';

export enum SLIDE_TYPE {
  image,
  banner,
  recommendation,
}

export type RecommendedClustersType = (CardData & {
  topicInfo: StateTopicType | undefined;
})[];

export type PageHashPropsType = {
  hash: string;
  pathname: string;
  search: string;
  replace: (link: string) => void;
};

export type SlidesConfigListType = {
  type: SLIDE_TYPE;
  hash: string;
  component: JSX.Element;
};

export type SlidesConfigListDesktopPropsType = {
  cluster: ClusterData | undefined;
  clusterPuids: PuidsType | undefined;
  recommendedClusters: RecommendedClustersType;
  bannersVisible?: boolean;
};

export type SlidesConfigListMobilePropsType = {
  cluster: ClusterData;
  textScaleValue: TextScaleValueType;
};

export type FilteredClustersPropsType = {
  clusterFeed: string[];
  topicClusterIds: StateTopicType['clusterIds'];
  entries: (ClusterData | CardData)[];
};

export type InitialIndexPropsType = {
  clusterIndex: number;
  slidesConfigList: SlidesConfigListType[];
};

export type CounterPropsType = {
  slideIndex: number;
  galleryLength: number;
};

export type UseSwipeType = {
  runtime: Runtime;
  slidesConfigList: SlidesConfigListType[];
  setSlideIndex: React.Dispatch<React.SetStateAction<number>>;
};

export type OnSwipedDesktopType = {
  direction: 'forward' | 'back';
  callbackSlide: () => void;
};

export type OnSwipedMobileType = { slideIndex: number };
