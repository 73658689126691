import { blockGenerator } from '@media-components/draft-parser';
import _uniqueid from 'lodash.uniqueid';

import { CUSTOM_BLOCK_TYPE } from 'common/components/ClusterContentOnDraft/typings';

/** Тип данных для медийной секции внутри статьи.(взято из старой реализации)  */
export type ArticleMediaSectionDataType = {
  /** Индекс кластера в списке кластеров. */
  clusterFeedListIndex: number;
  /** Флаг, что галлерея уже встроена в статью */
  hasGallery: boolean;
};

/**
 * Блок медиа секции.
 * @see ArticleMediaSectionDataType ;
 */
export const articleMediaSectionBlockGenerator = ({
  clusterFeedListIndex,
  hasGallery,
}: ArticleMediaSectionDataType) =>
  blockGenerator({
    key: `${CUSTOM_BLOCK_TYPE.ArticleMediaSection}_${_uniqueid()}`,
    type: CUSTOM_BLOCK_TYPE.ArticleMediaSection,
    data: {
      clusterFeedListIndex,
      hasGallery,
    },
  });
