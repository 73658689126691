const PARTNER_RESOURCE_ID = 7370;

const BANNER_CLUSTERS = ['49475250', '49583675', '51054603'];

/**
 * проверяет на принадлежность к партнерскому материалу
 * @param id - id ресурса
 */
export const checkIsPartnerMaterial = (
  id: ClusterResourceType['id'],
): boolean => id === PARTNER_RESOURCE_ID;

/**
 * Проверяет на персональный запрет кластеру показывать рекламу.
 * @param clusterId - id кластера.
 */
export const checkIsAdlessMaterial = (clusterId: ClusterData['id']): boolean =>
  BANNER_CLUSTERS.includes(clusterId!);
