import { EntityPropsType } from '@media-components/draft-parser';
import React, { memo } from 'react';

import { ExpertBase } from 'common/components/ExpertQuote/ExpertBase';

export type ExpertDataPropsType = {
  id: ATExpertType['id'];
  quote: string;
};

/**
 * Формирование эмбеда эксперта.
 * @param props - параметры компонента;
 * @param props.data.id - id эксперта;
 * @param props.data.height - высота формы.
 */
export const Expert = memo(
  ({ data }: EntityPropsType<ExpertDataPropsType, unknown>) => {
    if (!data || !data.id || !data.quote) {
      return null;
    }

    const { id, quote } = data;

    return (
      <ExpertBase expertId={id}>
        <p>{quote}</p>
      </ExpertBase>
    );
  },
);
