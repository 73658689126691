import { useCallback } from 'react';
import { useHistory } from 'react-router';

import { incrementCounters } from 'utils/counters';

import { setPageHash } from './helpers';
import { UseSwipeType, OnSwipedDesktopType, OnSwipedMobileType } from './types';

/**
 * Генерация обработчика свайпа для десктопа.
 * @param runtime - основной объект конфигурации приложения;
 * @param slidesConfigList - массив конфигруций сплитов;
 * @param setSlideIndex - сеттер для установки индекса слайда.
 */
export const useSwipeDesktop = (props: UseSwipeType) => {
  const { runtime, slidesConfigList, setSlideIndex } = props;

  const { location, replace } = useHistory();

  const onSwiped = useCallback(
    ({ direction, callbackSlide }: OnSwipedDesktopType) => {
      let slideIndex = 0;

      if (direction === 'forward') {
        setSlideIndex((prev) => {
          const value = prev + 1;

          slideIndex = value;

          return value;
        });
      } else {
        setSlideIndex((prev) => {
          const value = prev - 1;

          slideIndex = value;

          return value;
        });
      }

      const { hash } = slidesConfigList[slideIndex];

      setPageHash({
        hash,
        pathname: location.pathname,
        search: location.search,
        replace,
      });

      callbackSlide();

      incrementCounters({ runtime });
    },
    [runtime, slidesConfigList, setSlideIndex, location, replace],
  );

  return { onSwiped };
};

/**
 * Генерация обработчика свайпа для мобилы.
 * @param runtime - основной объект конфигурации приложения;
 * @param slidesConfigList - массив конфигруций сплитов;
 * @param setSlideIndex - сеттер для установки индекса слайда.
 */
export const useSwipeMobile = (props: UseSwipeType) => {
  const { runtime, slidesConfigList, setSlideIndex } = props;

  const { location, replace } = useHistory();

  const onSwiped = useCallback(
    ({ slideIndex }: OnSwipedMobileType) => {
      setSlideIndex(slideIndex);

      const { hash } = slidesConfigList[slideIndex];

      setPageHash({
        hash,
        pathname: location.pathname,
        search: location.search,
        replace,
      });

      incrementCounters({ runtime });
    },
    [runtime, slidesConfigList, setSlideIndex, location, replace],
  );

  return { onSwiped };
};
