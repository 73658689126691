/** Символы &,",',<,> которые должны учитываться как один */
const specialChars = ['&amp;', '&quot;', '&#x27;', '&lt;', '&gt;'];

/**
 * Очистка текста от артефактов
 * @param str - строка для очистки
 */
export const getPureText = (str: string | null): string =>
  str ? str.replace(/<\/?[^>]+>/g, '') : '';

/**
 * @see BROWSER_ONLY
 *
 * Принимает html с мнемониками типа &quot; и возвращает обычный текст
 * @param html - html с мнемониками
 */
export const decodeHTMLEntities = (html: string): string => {
  const textArea = document.createElement('textarea');

  textArea.innerHTML = html;

  return textArea.value;
};

/**
 * Очищает строку от спецсимволов типа &quot;, артефактов, \n, и преобразует множество пробелов в один
 * для корректного подсчета символов в кластере
 * @param str - строка для очистки
 */
export const cleanText = (str: string): string => {
  if (!str) return '';

  const isBlockquote = str.includes('blockquote');
  const pureString = getPureText(str);
  const specialCharsRegExp = new RegExp(`${specialChars.join('|')}`);
  // заменяем на один символ неважно какой, нужно для правильного подсчета символов в параграфах
  const strWithoutSpecialChars = pureString.replace(specialCharsRegExp, '"');
  const strWithoutBreakSpaces = strWithoutSpecialChars.replace(/\n/g, '');

  // если цитата, то заменяем множество пробелов идущих подряд на один, иначе просто тримим
  return isBlockquote
    ? strWithoutBreakSpaces.replace(/\s+/g, ' ')
    : strWithoutBreakSpaces.trim();
};

/**
 * @see BROWSER_ONLY
 *
 * Функция принимает html и возвращает html без embed'ов,
 * @param clusterBody - html c эмбедами
 */
export const getBodyWithoutEmbeds = (clusterBody: ClusterData['body']) => {
  const root = document.createElement('span');

  root.innerHTML = clusterBody;

  const divs = root.querySelectorAll(
    'div, .article__reference-link, .article__full-read-link',
  );

  divs.forEach((div) => {
    div.parentNode?.removeChild(div);
  });

  return root.innerHTML;
};
