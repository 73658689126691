const CLUSTER_TYPES_FOR_METRIKA = {
  0: 'default',
  1: 'photos',
  longread: 'longread',
  4: 'tests',
};

export const FULL_SCROLL_AFTER_READ_MORE = 101;

type FullScrollAfterReadMoreType = typeof FULL_SCROLL_AFTER_READ_MORE;
type ClusterTypeMetrikaKeys = keyof typeof CLUSTER_TYPES_FOR_METRIKA;
type ClusterTypeWithLongread = ClusterTypeType | 'longread';

/**
 * Функция для получения target в метрику при скроле до начала нового кластера
 * @param clusterType - тип кластера
 * @param page - номер кластера при бесконечном скроле
 */
export const getClusterEndlessScrollGoals = (
  clusterType: ClusterTypeWithLongread,
  page: number,
): string =>
  `endless_cluster_scroll_cluster_type_${
    CLUSTER_TYPES_FOR_METRIKA[clusterType as ClusterTypeMetrikaKeys] ||
    CLUSTER_TYPES_FOR_METRIKA[0]
  }_page_${page}`;

/**
 * Функция для получения target в метрику при скроле до начала нового кластера и конца этого кластера
 * @param clusterType  - тип кластера
 * @param percent - процент скрола, 0 - начало скрола, 100 - конец скрола, FULL_SCROLL_AFTER_READ_MORE - конец скрола после нажатия кнопки "читать далее"
 */
export const getClusterPercentScrollGoals = (
  clusterType: ClusterTypeWithLongread,
  percent: 0 | 100 | FullScrollAfterReadMoreType,
) => {
  const scrollPercent = `scroll_${percent}`;

  return `scroll_percent_cluster_type_${
    CLUSTER_TYPES_FOR_METRIKA[clusterType as ClusterTypeMetrikaKeys] ||
    CLUSTER_TYPES_FOR_METRIKA[0]
  }_${percent > 100 ? 'drop_down_cluster_full_scroll' : scrollPercent}`;
};
