import cn from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useAnalytics } from 'common/hooks/useAnalytics';
import { ttsButtonAnalyticsConfig } from 'common/hooks/useAnalytics/configs';
import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';
import { selectIsMobile } from 'common/redux/runtime/selectors';
import { HeadphonesIcon } from 'icons';

import { TOP_100_VOLUME_VALUE, VOLUME_LABEL } from '../../../contants';
import { ChangeSpeak } from '../../typings';
import { playSpeech } from '../../utils';

type PropsType = {
  clusterId: string;
  clusterBody: string;
  clusterTitle: string;
  speakingClusterId: AppStatePagesClusterType['speaking']['clusterID'];
  speakingIsPaused: AppStatePagesClusterType['speaking']['pause'];
  containerClassName: string;
  iconClassName: string;
  iconVolumeClassName: string;
  onChange: ChangeSpeak;
  disabled: boolean;
};

/**
 * Кнопка воспроизведения трека.
 * @param props - пропсы компонента.
 * @param props.clusterId - id кластера, для которого отображается кнопка;
 * @param props.clusterTitle - заголовок кластера;
 * @param props.clusterBody - тело кластера;
 * @param props.speakingClusterId - id кластера, для которого в последний раз была активирована звукозапись;
 * @param props.speakingIsPaused - флаг, что звукозапись активирована;
 * @param props.containerClassName - строчка с классами для кнопки;
 * @param props.iconClassName - строчка с классами для иконки;
 * @param props.iconVolumeClassName - строчка со специфическими для иконки воспроизведения классами;
 * @param props.onChange - функция диспатча события изменения состояния звукозаписи;
 */
export const PlayButton = ({
  clusterId,
  clusterTitle,
  clusterBody,
  speakingClusterId,
  speakingIsPaused,
  containerClassName,
  iconClassName,
  iconVolumeClassName,
  onChange,
  disabled,
}: PropsType) => {
  // Локальное состояние нужно для перерендера компонента, т.к. проблемы скорее всего с гидрацией
  const [isDisabled, setIsDisabled] = useState<boolean | undefined>(undefined);

  const top100AttributeSpeechPlay =
    useTop100AttributeWithValue(TOP_100_VOLUME_VALUE);
  const isMobile = useSelector(selectIsMobile);

  const { reachClick } = useAnalytics(ttsButtonAnalyticsConfig, isMobile);

  const handlePlayButtonClick = useCallback(() => {
    playSpeech({
      clusterId,
      clusterTitle,
      clusterBody,
      speakingClusterId,
      speakingIsPaused,
      onChange,
    });

    if (isMobile) {
      reachClick();
    }
  }, [
    clusterBody,
    clusterId,
    clusterTitle,
    isMobile,
    onChange,
    reachClick,
    speakingClusterId,
    speakingIsPaused,
  ]);

  useEffect(() => {
    setIsDisabled(disabled);
  }, [disabled]);

  return (
    <button
      type="button"
      className={containerClassName}
      onClick={handlePlayButtonClick}
      aria-label={VOLUME_LABEL}
      disabled={isDisabled}
      {...top100AttributeSpeechPlay}
    >
      <HeadphonesIcon
        className={cn(
          !!iconVolumeClassName && iconVolumeClassName,
          iconClassName,
        )}
      />
    </button>
  );
};
