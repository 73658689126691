import cn from 'classnames';
import React, { memo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { useAnalytics } from 'common/hooks/useAnalytics';
import { expertLinkAnalyticsConfig } from 'common/hooks/useAnalytics/configs';
import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';
import { selectRuntime, selectIsMobile } from 'common/redux/runtime/selectors';
import { getExpertLink } from 'common/utils/getExpertLink';
import { StarIcon } from 'icons';

import s from './styles.module.css';

type ExpertSignaturesPropsType = {
  experts: (ATExpertType | undefined)[];
  customStyles?: StylesType;
  withIcon?: boolean;
};

/**
 * Компонент данных о всех экспертах, упомянутых в статье
 * @param experts - массив экспертов
 * @param customStyles - кастомные стили
 * @param withIcon - флаг наличия иконки эксперта
 */
export const ExpertSignatures = memo(
  ({
    experts,
    customStyles = {},
    withIcon = true,
  }: ExpertSignaturesPropsType) => {
    const runtime = useSelector(selectRuntime, shallowEqual);
    const isMobile = useSelector(selectIsMobile);
    const top100Attribute = useTop100AttributeWithValue('expert');

    const { reachClick } = useAnalytics(expertLinkAnalyticsConfig, isMobile);

    const handleClick = () => {
      if (isMobile) {
        reachClick();
      }
    };

    return (
      <span className={cn(s.container, customStyles.expertRoot)}>
        {experts.map((expert, index) => {
          const { alias = '', name } = expert || {};
          const isLastElement = index === experts.length - 1;

          return (
            <a
              key={alias}
              href={getExpertLink(runtime, alias)}
              className={cn(s.title, customStyles.expertTitle)}
              data-test="expert-signature-link"
              onClick={handleClick}
              {...top100Attribute}
            >
              {!!withIcon && (
                <StarIcon className={cn(s.icon, customStyles.expertIcon)} />
              )}
              <span className={cn(s.name, customStyles.expertName)}>
                {name}
              </span>
              <span>
                эксперт
                {isLastElement ? ' ' : ','}
              </span>
            </a>
          );
        })}
      </span>
    );
  },
);
