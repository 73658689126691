import {
  countTextLengthPerBlock,
  generateInjector,
  draftModifier,
} from '@media-components/draft-parser';

import { injectAdcenter } from './draftModifier/injectors/injectAdcenter';
import { injectContentBanner } from './draftModifier/injectors/injectContentBanner';
import { injectInread } from './draftModifier/injectors/injectInread';
import { injectMediaSection } from './draftModifier/injectors/injectMediaSection';
import { injectMediaSectionMobile } from './draftModifier/injectors/injectMediaSectionMobile';
import { injectRelated } from './draftModifier/injectors/injectRelated';
import { injectResourceRelated } from './draftModifier/injectors/injectResourceRelated';
import { injectSpeakableId } from './draftModifier/injectors/injectSpeakableId';

type UseModifyDraftType = (props: {
  draft: RawDraftContentState;
  clusterId: CardData['id'];
  clusterType: ClusterTypeType | undefined;
  clusterHasGallery: boolean;
  clusterFeedListIndex: number;
  resourceId: ClusterResourceType['id'];
  isMobile?: boolean;
  isPaid?: boolean;
  isMulticluster?: boolean;
  forceRedesign?: boolean;
}) => RawDraftContentState;

/**
 * Хук модификации драфта под условия текста десктопного и мобильного кластеров.
 * @param props.draft - блок драфта;
 * @param props.clusterId - id кластера;
 * @param props.clysterType - тип кластера;
 * @param props.clusterHasGallery - флаг, что у кластера уже есть галлерея;
 * @param props.clusterFeedListIndex - индекс кластера в бесконечном скролле;
 * @param props.resourceId - id ресурса кластера;
 * @param props.isMobile - флаг мобильной версии;
 * @param props.isPaid - флаг партнерского материала (кластера без рекламы в тексте);
 * @param props.isMulticluster - флаг, что это мультикластер;
 * @param props.forceRedesign - флаг, что это редизайн.
 */
export const useModifyDraft: UseModifyDraftType = ({
  draft,
  clusterId,
  clusterType,
  clusterHasGallery,
  clusterFeedListIndex,
  resourceId,
  isMobile,
  isPaid,
  isMulticluster = false,
  forceRedesign = false,
}) => {
  const blocksLength = countTextLengthPerBlock(draft.blocks);
  const isLongread = false;

  if (isMobile) {
    return draftModifier({
      draft,
      injectors: [
        generateInjector(injectContentBanner)({
          clusterId,
          blocksLength,
          isPaid,
        }),
        generateInjector(injectMediaSectionMobile)({
          clusterFeedListIndex,
          hasGallery: clusterHasGallery,
          isMulticluster,
        }),
        generateInjector(injectResourceRelated)({
          clusterId,
          resourceId,
          isMulticluster,
          forceRedesign,
        }),
        generateInjector(injectRelated)({ resourceId, isMulticluster }),
        generateInjector(injectSpeakableId)({}),
      ],
    });
  }

  return draftModifier({
    draft,
    injectors: [
      generateInjector(injectInread)({
        clusterId,
        isLongread,
        blocksLength,
        isPaid,
      }),
      generateInjector(injectMediaSection)({
        clusterFeedListIndex,
        hasGallery: clusterHasGallery,
        isMulticluster,
      }),
      generateInjector(injectAdcenter)({
        clusterId,
        clusterType,
        resourceId,
        isPaid,
        articleLength: blocksLength.fullLength,
      }),
      generateInjector(injectSpeakableId)({}),
    ],
  });
};
