import React from 'react';

import { CarouselSlide } from './CarouselDesktop/components/CarouselSlide';
import stylesCarouselSlide from './CarouselDesktop/components/CarouselSlide/styles.module.css';
import { CarouselSlideBanner } from './CarouselDesktop/components/CarouselSlideBanner';
import stylesCarouselSlideBanner from './CarouselDesktop/components/CarouselSlideBanner/styles.module.css';
import { CarouselSlideRecommendation } from './CarouselDesktop/components/CarouselSlideRecommendation';
import stylesCarouselSlideRecommendation from './CarouselDesktop/components/CarouselSlideRecommendation/styles.module.css';
import { CarouselSlide as CarouselSlideMobile } from './CarouselMobile/components/CarouselSlide';
import {
  FIRST_SLIDE_INDEX,
  BANNER_REPEAT,
  MAX_RECOMMENDED_CLUSTERS_COUNT,
} from './constants';
import {
  SLIDE_TYPE,
  SlidesConfigListType,
  FilteredClustersPropsType,
  InitialIndexPropsType,
  PageHashPropsType,
  SlidesConfigListDesktopPropsType,
  SlidesConfigListMobilePropsType,
  CounterPropsType,
} from './types';

/**
 * Получение отфильтрованных кластеров.
 * @param clusterFeed – массив id кластеров;
 * @param formatTopics – массив id кластеров форматных топиков;
 * @param entries – массив всех полученнных кластеров.
 */
export const getFilteredClusters = (
  props: FilteredClustersPropsType,
): (CardData | ClusterData)[] => {
  const { clusterFeed, topicClusterIds, entries } = props;

  const clusterIds = topicClusterIds.filter(
    (clusterId) => !clusterFeed.includes(clusterId),
  );

  return clusterIds
    .slice(0, MAX_RECOMMENDED_CLUSTERS_COUNT)
    .map((clusterId) =>
      entries.find(({ id: entrieId }) => clusterId === entrieId),
    ) as (CardData | ClusterData)[];
};

/**
 * Получение hash из url.
 */
export const getPageHash = (): string | null => {
  if (!__BROWSER__) {
    return null;
  }

  return window.location.hash.slice(1);
};

/**
 * Вычисление начального слайда.
 * @param clusterIndex - индекс кластера в бесконечном скролле;
 * @param slidesConfigList - массив конфигруций сплитов.
 */
export const getInitialIndex = (props: InitialIndexPropsType): number => {
  const { clusterIndex, slidesConfigList } = props;

  const isFirstCluster = clusterIndex === 0;

  if (isFirstCluster) {
    const pageHash = getPageHash();

    if (pageHash) {
      const slideIndex = slidesConfigList.findIndex(
        (slideObj) => slideObj.hash === pageHash,
      );
      const isSlideExists = slideIndex !== -1;

      if (isSlideExists) {
        return slideIndex;
      }
    }
  }

  return FIRST_SLIDE_INDEX;
};

/**
 * Добавляет хэш слайда в url hash.
 * @param hash - хэш слайда;
 * @param pathname - путь страницы;
 * @param search - параметры запроса;
 * @param replace - функция добавления хэша.
 */
export const setPageHash = ({
  hash,
  pathname,
  search,
  replace,
}: PageHashPropsType): void => {
  replace(`${pathname}${search}#${hash}`);
};

/**
 * Получение дробного счетчика.
 * @param slideIndex - индекс слайда;
 * @param galleryLength - длина галереи у кластера.
 */
export const getCounter = ({
  slideIndex,
  galleryLength,
}: CounterPropsType): string => `${slideIndex}/${galleryLength}`;

/**
 * Генерация массива слайдов для десктопа.
 * @param cluster - кластер;
 * @param clusterPuids - рекламные пуиды кластер;
 * @param recommendedClusters - рекомендательные кластера;
 * @param bannersVisible - видимы ли баннеры.
 */
export const getSlidesConfigListDesktop = (
  props: SlidesConfigListDesktopPropsType,
): SlidesConfigListType[] => {
  const {
    cluster,
    clusterPuids,
    recommendedClusters,
    bannersVisible = true,
  } = props;

  const slidesList: SlidesConfigListType[] = [];

  cluster?.gallery?.forEach((slide, index) => {
    const slideIndex = index + 1;
    const isBannerSlide = slideIndex % BANNER_REPEAT === BANNER_REPEAT - 1;
    const isInsertBanner =
      bannersVisible &&
      index > 0 &&
      slideIndex !== cluster.gallery.length &&
      isBannerSlide;

    slidesList.push({
      type: SLIDE_TYPE.image,
      hash: `${slideIndex}`,
      component: (
        <CarouselSlide
          key={slide.id}
          slide={slide}
          slideIndex={slideIndex}
          cluster={cluster}
          styles={stylesCarouselSlide}
        />
      ),
    });

    if (isInsertBanner) {
      slidesList.push({
        type: SLIDE_TYPE.banner,
        hash: `a${slideIndex}`,
        component: (
          <CarouselSlideBanner
            key={slideIndex}
            puids={clusterPuids}
            styles={stylesCarouselSlideBanner}
          />
        ),
      });
    }
  });

  slidesList.push({
    type: SLIDE_TYPE.recommendation,
    hash: 'more',
    component: (
      <CarouselSlideRecommendation
        key="more"
        recommendedClusters={recommendedClusters}
        styles={stylesCarouselSlideRecommendation}
      />
    ),
  });

  return slidesList;
};

/**
 * Генерация массива слайдов для мобилы.
 * @param cluster - кластер;
 * @param textScaleValue - значение маштабируемости текста.
 */
export const getSlidesConfigListMobile = (
  props: SlidesConfigListMobilePropsType,
): SlidesConfigListType[] => {
  const { cluster, textScaleValue } = props;

  const slidesList: SlidesConfigListType[] = [];

  cluster?.gallery?.forEach((slide, index) => {
    const slideIndex = index + 1;

    slidesList.push({
      type: SLIDE_TYPE.image,
      hash: `${slideIndex}`,
      component: (
        <CarouselSlideMobile
          key={slide.id}
          slide={slide}
          slideIndex={slideIndex}
          cluster={cluster}
          textScaleValue={textScaleValue}
        />
      ),
    });
  });

  return slidesList;
};
