import cn from 'classnames';
import React, { memo } from 'react';

import { CommentsButton as CommentsButtonComponent } from 'common/components/buttons/CommentsButton';
import { selectIsHumanCenteredStrategyStateActivated } from 'common/redux/runtime/selectors';
import { useAppSelector } from 'store/hooks';

import s from './styles.module.css';

type CommentsButtonPropsType = {
  clusterId: CardData['id'] | null;
  dataBlocks?: string;
};

/**
 * Компонент кнопки комментов для мобильной версии.
 * @param clusterID - id кластера;
 * @param dataBlocks - аттрибут метрики топ100.
 */
export const CommentsButton = memo(function CommentsButton({
  clusterId,
  dataBlocks,
}: CommentsButtonPropsType) {
  const forceRedesign = useAppSelector(
    selectIsHumanCenteredStrategyStateActivated,
  );

  return (
    <CommentsButtonComponent
      clusterId={clusterId}
      className={cn(s.commentsButton, forceRedesign && s.redesign)}
      countClassName={cn(s.commentsCounter, forceRedesign && s.redesign)}
      top100={dataBlocks}
    />
  );
});
