import { blockGenerator } from '@media-components/draft-parser';
import _uniqueid from 'lodash.uniqueid';
import { CSSProperties } from 'react';

import { CUSTOM_BLOCK_TYPE } from 'common/components/ClusterContentOnDraft/typings';
import Banners from 'config/constants/banner/banners';

/**
 * Список обрабатываемых слотов для вставки.
 *  Слот обрабатывается не внутри непосредственной установки
 *  чтобы не загрязнять модификатор драфта jsx кодом.
 */
export enum BANNER_REPLACEMENT_SLOT {
  VideoAdIframe = 'video-ad-iframe',
}

/** Тип данных для баннера внутри статьи. */
export type BannerDataType = {
  /** Наименование баннера */
  name: Banners;
  /** Флаг ленивой загрузки баннера */
  isLazy?: boolean;
  /** Дополнительные стили для отрисовки баннера */
  onRenderStyle?: CSSProperties;
  /** Дополнительный класс для баннера */
  className?: string;
  /** Блок, заменяющий баннер, если последний не загрузился */
  bannerReplacementSlot?: BANNER_REPLACEMENT_SLOT;
  /** id кластера, для которого рисуется блок */
  clusterId?: CardData['id'];
};

/**
 * Генератор блока с рекламой.
 * @see BannerDataType ;
 */
export const bannerBlockGenerator = ({
  name,
  isLazy,
  onRenderStyle,
  className,
  bannerReplacementSlot,
  clusterId,
}: BannerDataType) =>
  blockGenerator({
    key: `${CUSTOM_BLOCK_TYPE.Banner}_${name}_${_uniqueid()}`,
    type: CUSTOM_BLOCK_TYPE.Banner,
    data: {
      name,
      isLazy: isLazy || false,
      onRenderStyle,
      className,
      bannerReplacementSlot,
      clusterId,
    },
  });
