import cn from 'classnames';
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { RamblerButton } from 'common/components/RamblerComponents/RamblerButton';
import { useSafeMediaQuery } from 'common/hooks/useSafeMediaQuery';
import { WINDOW_WIDTH_COMPACT } from 'config/constants/css';
import { ArrowDownIcon } from 'icons';

type ExpandableDescriptionPropsType = {
  description: string;
  styles: StylesType;
};

/**
 * Компонент сворачивающегося/разворачивающегося описания для слайда. Отображает первые 2 строки описания, остальное скрывает.
 * @param description – текст описание для слайда;
 * @param styles - стили компонента.
 */
export const ExpandableDescription = memo(
  (props: ExpandableDescriptionPropsType) => {
    const { description, styles } = props;

    const expandWrapperRef = useRef<HTMLDivElement>(null);
    const descriptionRef = useRef<HTMLDivElement>(null);
    /** Флаг что есть возможность скрывать/раскрывать описание */
    const [isExpandable, setIsExpandable] = useState(false);
    /** Флаг что описание раскрыто */
    const [isExpanded, setIsExpanded] = useState(false);

    const isCompact = useSafeMediaQuery({ maxWidth: WINDOW_WIDTH_COMPACT });

    useEffect(() => {
      if (!expandWrapperRef.current || !descriptionRef.current) return;

      // Нужно для фикса отображения кнопки если развернули описание на разрешении <WINDOW_WIDTH_COMPACT, затем увеличили и снова уменьшили разрешение
      setIsExpanded(false);

      const expandWrapperHeight =
        expandWrapperRef.current.getBoundingClientRect()?.height ?? 0;
      const descriptionHeight =
        descriptionRef.current.getBoundingClientRect()?.height ?? 0;

      // Если высота описания больше ограничивающей высоту обертки, значит описание можно раскрыть
      setIsExpandable(expandWrapperHeight < descriptionHeight);

      // Добавляем в зависимость isCompact тк при смене ширины вьюпорта высота description может измениться
    }, [isCompact]);

    const toggleDescription = useCallback(
      () => setIsExpanded((prevValue) => !prevValue),
      [],
    );

    const icon = useMemo(
      () => <ArrowDownIcon className={styles.icon} />,
      [styles.icon],
    );

    if (!description) return null;

    return (
      <div
        className={cn(!isExpanded && styles.expandWrapper)}
        ref={expandWrapperRef}
      >
        <div className={styles.description} ref={descriptionRef}>
          {description}&nbsp;
          {isExpandable && (
            <RamblerButton
              size="small"
              onClick={toggleDescription}
              type="white"
              aria-label={`${isExpanded ? 'Свернуть' : 'Развернуть'} описание`}
              icon={icon}
              iconPosition="right"
              className={cn(styles.toggler, !isExpanded && styles.toggler_down)}
            />
          )}
        </div>
      </div>
    );
  },
);
