import { paragraphTypesList } from 'common/utils/clusterContent/getParagraphType';

import { PARAGRAPH_TYPE } from './config';

export type ParagraphConfigType = {
  /** Тип параграфа для рендера */
  type: PARAGRAPH_TYPE;
  /** Текст/верстка самого параграфа */
  text: string;
  /** Количество символов в параграфе */
  paragraphLength: number;
  /** Количество символов во всех предыдущих + этом параграфе */
  accumulatedLength: number;
  /** Конфигурация параграфа */
  config: ContentConfig | undefined;
  /** Компонент отрисовки параграфа */
  Component: (typeof paragraphTypesList)[PARAGRAPH_TYPE];
  /** Номер вставляемого баннера */
  bannerIndex?: number;
};

export type ParagraphArr = {
  /** Параграфы, вставляемые до ката */
  paragraphsListBeforeCut: ParagraphConfigType[];
  /** Параграфы, вставляемые в кат */
  paragraphsListInCut: ParagraphConfigType[];
};

/** Тип применяемого CUT */
export enum CUT_TYPE {
  /** Без ката */
  NO_CUT,
  /** Коротки кат: Cut (укороченный) desktop */
  SHORT,
  /** Начало после первого баннера + параграф: CUT (укороченный) mobile */
  FIRST_BANNER,
  /** Ориентир на количество символов в тексте: CUT (стандартный) mobile */
  ARTICLE_LENGTH,
  /** Кат для трафика источникам */
  RESOURCE_CUT,
}

export const CUT_TOP100 = {
  [CUT_TYPE.NO_CUT]: '',
  [CUT_TYPE.SHORT]: 'readmore::cut_short',
  [CUT_TYPE.FIRST_BANNER]: 'readmore::cut_short',
  [CUT_TYPE.ARTICLE_LENGTH]: 'readmore::cut_standart',
  [CUT_TYPE.RESOURCE_CUT]: '',
};

/**
 * Набор опций для функций трансформации статьи в параграфы на мобиле
 */
export type UseParagraphMobOptionsType = {
  cutType?: CUT_TYPE;
  withFilterTags?: boolean;
  isAmp?: boolean;
  isAppDesign?: boolean;
  isWebView?: boolean;
};

/**
 * Тип для хука трансформации статьи в параграфы на мобилке
 */
export type UseParagraphsMobType = {
  clusterBody: string;
  autotags: ATAutotag[] | undefined;
  draft?: RawDraftContentState;
  options: UseParagraphMobOptionsType;
};

/**
 * Набор опций для функций трансформации статьи в параграфы на десктопе
 */
export type UseParagraphDeskOptionsType = {
  cut?: CUT_TYPE;
  withFilterTags?: boolean;
  isLong?: boolean;
  isAppDesign?: boolean;
};

/**
 * Тип для хука трансформации статьи в параграфы на десктопе
 */
export type UseParagraphsDeskType = {
  clusterBody: string;
  autotags: ATAutotag[] | undefined;
  draft: RawDraftContentState | undefined;
  options: UseParagraphDeskOptionsType;
};

/**
 * Набор опций для функций трансформации статьи в параграфы
 */
export type GetArticleOptionsType = {
  isLongread?: boolean;
  withFilterTags?: boolean;
  onlyFirst?: boolean;
  isAppDesign?: boolean;
  isMobile?: boolean;
};
