import { InjectorType } from '@media-components/draft-parser';

import { resourceRelatedBlockGenerator } from 'common/components/ClusterContentOnDraft/draftModifier/generators/resourceRelatedBlockGenerator';
import {
  RESOURCES_EXCLUDED_FOR_REDESIGN,
  RNET_BLOCK_ID_FOR_RESOURCES,
} from 'config/constants/resourceRnet';

type Props = {
  clusterId: ClusterData['id'];
  resourceId: ClusterData['resourceId'];
  isMulticluster: boolean;
  forceRedesign: boolean;
};

/**
 * Инжектор новостей Rnet-источника.
 * @param props.blocks - блоки драфта;
 * @param props.clusterId - id кластера;
 * @param props.resourceId - id источника кластера;
 * @param props.isMulticluster - флаг, что это мультикластер;
 * @param props.forceRedesign - флаг, что это редизайн.
 */
export const injectResourceRelated: InjectorType<Props> = ({
  blocks,
  clusterId,
  resourceId,
  isMulticluster,
  forceRedesign,
}) => {
  const isRnet =
    !!RNET_BLOCK_ID_FOR_RESOURCES[
      resourceId as keyof typeof RNET_BLOCK_ID_FOR_RESOURCES
    ];
  const isRamblerResource = resourceId
    ? RESOURCES_EXCLUDED_FOR_REDESIGN.includes(resourceId)
    : false;

  if (isMulticluster || !isRnet || (forceRedesign && isRamblerResource)) {
    return blocks;
  }

  const resourceRelatedSection = resourceRelatedBlockGenerator({
    clusterId,
    resourceId,
  });

  return [...blocks, resourceRelatedSection];
};
