import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import {
  selectProjectId,
  selectDomainConfig,
} from 'common/redux/runtime/selectors';
import { cropImage } from 'common/utils/crop';
import { s3Image, s3Resize, s3Format } from 'common/utils/s3';
import { AUTO } from 'config/constants/s3ImageTypes';
import { domainGenerator } from 'utils/urlGenerator';

import { IMAGE_WIDTH, IMAGE_HEIGHTS, IMAGE_CROP_STRINGS } from './constants';

/**
 * Хук для получения массива урлов картинок SchemaOrg
 * @param url - url картинки
 * @param s3 - флаг что картинка из s3
 */
export const useGetImageSrc = (url: string, s3: boolean): string[] => {
  let images: string[];
  const domainConfig = useSelector(selectDomainConfig, shallowEqual);
  const projectId = useSelector(selectProjectId);
  const domain = useMemo(
    () => domainGenerator(domainConfig, projectId),
    [domainConfig, projectId],
  );

  const noImgUrl = `${domain}/special/no_img.jpg`;

  if (url) {
    if (s3) {
      images = IMAGE_HEIGHTS.map((cropHeight: number) =>
        s3Image(url, [
          s3Resize({ number: 1, width: IMAGE_WIDTH, height: cropHeight }),
          s3Format(2, AUTO),
        ]),
      );
    } else {
      images = IMAGE_HEIGHTS.map((cropHeight: number) =>
        cropImage(url, IMAGE_CROP_STRINGS[cropHeight]),
      );
    }
  } else {
    images = IMAGE_HEIGHTS.map((cropHeight: number) =>
      cropImage(noImgUrl, IMAGE_CROP_STRINGS[cropHeight]),
    );
  }

  return images;
};
