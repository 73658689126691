import React, { useCallback, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { RamblerLoader } from 'common/components/RamblerComponents/RamblerLoader';
import { useObserver } from 'common/hooks/useObserver';
import { addPuidsToEntry } from 'common/redux/commonData/entries';
import { fetchFullCluster } from 'common/redux/commonData/entries/asyncs';
import { selectClusterPageClusterFeed } from 'common/redux/pages/cluster/selectors';
import { selectIsBot, selectIsMobile } from 'common/redux/runtime/selectors';
import { useAppDispatch, useAppStore } from 'store/hooks';

type UseClusterFeedARGType = {
  feedLength: number;
  styles?: StylesType;
};

type UseClusterFeedReturnsType = {
  /**
   * Есть ещё что загружать.
   */
  isActive: boolean;
  /**
   * Компонент, запускающий загрузку следующего кластера.
   */
  triggerComponent: JSX.Element | null;
  /**
   * Номер загружающегося кластера.
   */
  currentLoadingCluster: string | null;
};

type UseClusterFeedType = ({
  feedLength,
  styles,
}: UseClusterFeedARGType) => UseClusterFeedReturnsType;

const RAMBLER_LOADER_STYLES = {
  marginBottom: '20px',
  marginLeft: 'auto',
  marginRight: 'auto',
};

/**
 * Хук для формирования бесконечного скролла на странице кластера
 * @param feedLength - длина бесконечного скоролла
 * @param styles - стили для лоудера
 */
export const useClusterFeed: UseClusterFeedType = ({
  feedLength,
  styles = {},
}) => {
  const dispatch = useAppDispatch();

  const { getState } = useAppStore();

  const clusterFeed = useSelector(selectClusterPageClusterFeed, shallowEqual);
  const isBot = useSelector(selectIsBot);
  const isMobile = useSelector(selectIsMobile);

  /*
    Счётчик, чтобы мы бесконечно не запрашивали ручку,
    если она не отдаёт кластера,
    из-за условия active: feedList.length < FEED_LENGTH,
    по дефолту = 1, потому что первый кластер в списке уже получен (основной)
   */
  const [requestsCount, setRequestsCount] = useState(1);
  const [currentLoadingCluster, setCurrentLoadingCluster] = useState<
    string | null
  >(null);

  /** Функция дозагрузки кластера в ленту */
  const loadNextClusterInFeed = useCallback(async () => {
    const nextLoadClusterId = clusterFeed[requestsCount];

    if (nextLoadClusterId) {
      setCurrentLoadingCluster(nextLoadClusterId);
      await dispatch(fetchFullCluster({ clusterId: nextLoadClusterId }));
      dispatch(
        addPuidsToEntry({ clusterId: nextLoadClusterId, appState: getState() }),
      );
      setCurrentLoadingCluster(null);
      setRequestsCount((state) => state + 1);
    }
  }, [dispatch, getState, clusterFeed, requestsCount]);

  const intersectionRef = useObserver<HTMLDivElement>({
    callback: loadNextClusterInFeed,
  });

  const isActive = requestsCount < feedLength;

  const isLoading = currentLoadingCluster !== null;

  const LoaderComponent = isLoading && (
    <RamblerLoader
      size={isMobile ? 'small' : 'medium'}
      className={styles.loader}
      style={RAMBLER_LOADER_STYLES}
    />
  );

  const ObserverComponent = !isLoading && isActive && (
    <div style={{ position: 'relative', top: '-50vh' }} ref={intersectionRef} />
  );

  const TriggerComponent = (
    <React.Fragment key="trigger-component">
      {LoaderComponent}
      {ObserverComponent}
    </React.Fragment>
  );

  return {
    isActive,
    triggerComponent: isBot ? null : TriggerComponent,
    currentLoadingCluster,
  };
};
