import cn from 'classnames';
import React, { useCallback } from 'react';

import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';
import { HeadphonesIcon } from 'icons';

import { MUTE_LABEL, TOP_100_MUTE_VALUE } from '../../../contants';
import { ChangeSpeak } from '../../typings';
import { pauseSpeech } from '../../utils';

type PropsType = {
  containerClassName: string;
  iconClassName: string;
  iconMuteClassName: string;
  onChange: ChangeSpeak;
};

/**
 * Кнопка остановки воспроизведения трека.
 * @param props - пропсы компонента.
 * @param props.containerClassName - строчка с классами для кнопки;
 * @param props.iconClassName - строчка с классами для иконки;
 * @param props.iconMuteClassName - строчка со специфическими для иконки паузы классами;
 * @param props.onChange - функция диспатча события изменения состояния звукозаписи;
 */
export const PauseButton = ({
  containerClassName,
  iconClassName,
  iconMuteClassName,
  onChange,
}: PropsType) => {
  const top100Attribute = useTop100AttributeWithValue(TOP_100_MUTE_VALUE);

  const handleClick = useCallback(() => pauseSpeech(onChange), [onChange]);

  return (
    <button
      type="button"
      className={containerClassName}
      onClick={handleClick}
      aria-label={MUTE_LABEL}
      {...top100Attribute}
    >
      <HeadphonesIcon
        className={cn(!!iconMuteClassName && iconMuteClassName, iconClassName)}
      />
    </button>
  );
};
