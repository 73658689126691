import { useCallback, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { RouteConfig, matchRoutes } from 'react-router-config';

import { useScroll } from 'common/hooks/useScrollResize';
import { StateTopicType } from 'common/redux/commonData/topics/typings';
import { setCurrentClusterId } from 'common/redux/pages/cluster';
import {
  selectDomainConfig,
  selectIsMobile,
} from 'common/redux/runtime/selectors';
import { getCommonRoutes } from 'common/routes';
import { PAGE_TYPE } from 'config/constants/routerName';
import { generateClusterUrl } from 'utils/urlGenerator';

import { ROUTER_UPDATE_EVENT_NAME } from './contants';

type UseClusterChangeUrlPropsType = {
  clusterFeed: CardData['id'][];
  clustersWithTopics: (ClusterData & { topicData: StateTopicType })[];
  nodesRefs: React.MutableRefObject<HTMLDivElement[]>;
};

/**
 * Хук динамической смены урла на странице кластера
 * @param props.clusterFeed - массив кластеров в бесконечном скролле
 * @param props.clustersWithTopics - массив кластеров с топиками
 * @param props.nodesRefs - массив ссылок кластеров
 */
export const useClusterChangeUrl = (props: UseClusterChangeUrlPropsType) => {
  const { clusterFeed, clustersWithTopics, nodesRefs } = props;

  const dispatch = useDispatch();

  const { clusterId: routerClusterId } = useParams<{ clusterId: string }>();

  const { replace, location } = useHistory();
  const { search, hash, pathname } = location;

  const domainConfig = useSelector(selectDomainConfig, shallowEqual);
  const isMobile = useSelector(selectIsMobile);

  const routes = getCommonRoutes(isMobile);

  useEffect(() => {
    const {
      normalizedTitle,
      id: clusterId,
      topicData,
    } = clustersWithTopics.find((cluster) => cluster?.id === routerClusterId) ||
    {};

    if (normalizedTitle) {
      const clusterUrl = generateClusterUrl({
        domainConfig,
        normalizedTitle,
        clusterId,
        topic: topicData,
        addDomain: false,
      });

      const newUrl = `${clusterUrl}${search}${hash}`;

      replace(newUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Метод смены урла страницы
  const changeUrl = useCallback(
    (index: number) => {
      const [{ route }] = matchRoutes(routes as RouteConfig[], pathname);
      if (route.name !== PAGE_TYPE.cluster) return;

      if (clusterFeed[index] !== routerClusterId) {
        const {
          normalizedTitle,
          id: clusterId,
          topicData,
        } = clustersWithTopics.find(
          (cluster) => cluster?.id === clusterFeed[index],
        ) || {};

        if (!normalizedTitle) return;

        const clusterUrl = generateClusterUrl({
          domainConfig,
          normalizedTitle,
          clusterId,
          topic: topicData,
          addDomain: false,
        });

        const newUrl = `${clusterUrl}${search}`;

        replace(newUrl);

        window.dispatchEvent(new CustomEvent(ROUTER_UPDATE_EVENT_NAME));

        dispatch(setCurrentClusterId(clusterId));
      }
    },
    [
      routes,
      pathname,
      clusterFeed,
      routerClusterId,
      clustersWithTopics,
      domainConfig,
      search,
      replace,
      dispatch,
    ],
  );

  // Вычисление положения viewport относительно кластеров
  useScroll(
    () => {
      const filterNode = nodesRefs.current.filter((node) => node);

      if (filterNode.length <= 1) return;

      let prevNode = null;

      for (let index = 0; index < filterNode.length; index++) {
        const node = filterNode[index];

        if (prevNode) {
          const { bottom: prevBottom } = prevNode.getBoundingClientRect();
          const { top } = node.getBoundingClientRect();

          if (prevBottom >= 0 && top >= 0) {
            const viewportHeight = document.documentElement.clientHeight;
            const currentIndex = Number(node?.getAttribute('data-index')) ?? 0;
            const prevNodeIndex =
              Number(prevNode?.getAttribute('data-index')) ?? 0;

            changeUrl(
              viewportHeight - prevBottom > viewportHeight * 0.4
                ? currentIndex
                : prevNodeIndex,
            );
            break;
          } else {
            prevNode = node;
          }
        } else {
          prevNode = node;
        }
      }
    },
    { ms: 0 },
  );
};
