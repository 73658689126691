import { InjectorType } from '@media-components/draft-parser';

import { articleMediaSectionBlockMobileGenerator } from '../../generators/articleMediaSectionBlockMobileGenerator';

type Props = {
  clusterFeedListIndex: number;
  hasGallery: boolean;
  isMulticluster: boolean;
};

/**
 * Инжектор медиа секции для мобильного кластера.
 * @param props.blocks - блоки драфта;
 * @param props.clusterFeedListIndex - индекс кластера в списке кластеров;
 * @param props.hasGallery - флаг, что галерея уже есть у кластера;
 * @param props.isMulticluster - флаг, что это мультикластер.
 */
export const injectMediaSectionMobile: InjectorType<Props> = ({
  blocks,
  clusterFeedListIndex,
  hasGallery,
  isMulticluster,
}) => {
  const mediaSection = articleMediaSectionBlockMobileGenerator({
    clusterFeedListIndex,
    hasGallery,
  });

  if (isMulticluster) {
    return [...blocks, mediaSection];
  }

  return [...blocks.slice(0, 1), mediaSection, ...blocks.slice(1)];
};
