import cn from 'classnames';
import React, { memo, useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { TextScale } from 'common/components/ClusterControl/components/TextScale';
import { TextToSpeech } from 'common/components/ClusterControl/components/TextToSpeech';
import { selectClusterById } from 'common/redux/commonData/entries/selectors';
import {
  clusterChangeSpeech,
  clusterDecrementTextScale,
  clusterIncrementTextScale,
} from 'common/redux/pages/cluster';
import {
  selectClusterPageSpeaking,
  selectClusterPageTextScale,
} from 'common/redux/pages/cluster/selectors';
import { selectIsMobile } from 'common/redux/runtime/selectors';

type ClusterControlPropsType = {
  clusterId: ClusterData['id'];
  customStyles?: StylesType;
  textScaleMax?: number;
  textScaleMin?: number;
};

const DEFAULT_STYLES = {} as StylesType;

/**
 * Панель управления кластером (скейл текста, воспроизведение)
 * @param textToSpeechVisible - флаг отображения кнопки озвучки текста
 * @param customStyles - объект стилизации компонента
 * @param textScaleMax - максимальное увеличение текста
 * @param textScaleMin - минимальное увеличение текста
 */
export const ClusterControl = memo(
  ({
    clusterId,
    customStyles = DEFAULT_STYLES,
    textScaleMax,
    textScaleMin,
  }: ClusterControlPropsType) => {
    const dispatch = useDispatch();

    const textScaleDown = useCallback(
      () => dispatch(clusterDecrementTextScale()),
      [dispatch],
    );
    const textScaleUp = useCallback(
      () => dispatch(clusterIncrementTextScale()),
      [dispatch],
    );
    const changeSpeak = useCallback(
      (speech: AppStatePagesClusterType['speaking']) => {
        dispatch(clusterChangeSpeech(speech));
      },
      [dispatch],
    );

    const isMobile = useSelector(selectIsMobile);
    const textScaleValue = useSelector(selectClusterPageTextScale);
    const speaking = useSelector(selectClusterPageSpeaking, shallowEqual);
    const cluster = useSelector(selectClusterById(clusterId), shallowEqual);

    return (
      <div
        className={cn(
          customStyles.controlWrapper,
          isMobile && customStyles.mobile,
        )}
      >
        <TextScale
          textScaleValue={textScaleValue}
          textScaleUp={textScaleUp}
          textScaleDown={textScaleDown}
          customStyles={customStyles}
          textScaleMax={textScaleMax}
          textScaleMin={textScaleMin}
          isMobile={isMobile}
        />
        <TextToSpeech
          clusterId={cluster?.id ?? ''}
          clusterTitle={cluster?.longTitle ?? ''}
          clusterBody={cluster?.body ?? ''}
          speaking={speaking}
          changeSpeak={changeSpeak}
          customStyles={customStyles}
          isMobile={isMobile}
        />
      </div>
    );
  },
);
