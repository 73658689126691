import cn from 'classnames';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { RamblerImageBg } from 'common/components/RamblerComponents/RamblerImage';
import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';
import { selectClusterPageTextScale } from 'common/redux/pages/cluster/selectors';
import { selectProjectAlias } from 'common/redux/runtime/selectors';
import { getUrlWithUtmParams } from 'utils/getUrlWithUtmParams';

type ItemPropsType = {
  title: string;
  url: string;
  index: number;
  favicon: string;
  styles?: StylesType;
};

/**
 * Компонент элемента новостей партнеров.
 * @param props.title - заголовок новости;
 * @param props.url - ссылка, на которую ведет новость;
 * @param props.index - позиция айтема среди блока новостей партнеров;
 * @param props.favicon - ссылка на иконку источника;
 */
export const Item = memo(
  ({ title, url, index, favicon, styles }: ItemPropsType) => {
    const projectAlias = useSelector(selectProjectAlias);
    const textScaleValue = useSelector(selectClusterPageTextScale);

    const top100Attribute = useTop100AttributeWithValue(`item::${index + 1}`);

    return (
      <a
        href={getUrlWithUtmParams({
          url,
          params: { source: `r${projectAlias}`, medium: 'more' },
          deleteInitialUtm: true,
        })}
        target="_blank"
        rel="nofollow noopener noreferrer"
        className={styles?.item}
        {...top100Attribute}
        data-test="related-news-link"
      >
        {favicon ? (
          <div className={styles?.item__container__image}>
            <RamblerImageBg
              className={styles?.item__image}
              width={13}
              height={13}
              src={favicon}
            />
          </div>
        ) : (
          <div className={styles?.item__mark} />
        )}
        <div
          className={cn(
            styles && {
              [styles.item__text]: !!styles.item__text,
              [styles[`item__text_${textScaleValue}`]]:
                !!styles[`item__text_${textScaleValue}`],
              [styles.item__textWithFavicon]:
                !!favicon && !!styles.item__textWithFavicon,
            },
          )}
          data-test="related-news-title"
        >
          {title}
        </div>
      </a>
    );
  },
);
