import React, { memo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { EditorInfo } from 'common/components/SourceBlock/components/EditorInfo';
import { selectClusterEditor } from 'common/redux/commonData/editors/selectors';
import {
  selectClusterById,
  selectClusterResource,
} from 'common/redux/commonData/entries/selectors';
import { selectTopicByClusterId } from 'common/redux/commonData/topics/selectors';
import {
  selectProjectAlias,
  selectDomainConfig,
} from 'common/redux/runtime/selectors';
import { RAMBLER_RESOURCES_IDS } from 'config/constants/ramblerResourcesIds';
import { generateClusterUrl } from 'utils/urlGenerator';

import { SourceInfo } from './SourceInfo';

type ClusterSourcesPropsType = {
  clusterId: ClusterData['id'] | null;
  customStyles?: StylesType;
};

const DEFAULT_STYLE = {} as StylesType;

/**
 * Блок с информацией об авторе или источнике
 * @param clusterId - id кластера
 * @param customStyles - кастомные стили
 */
export const ClusterSources = memo(
  ({ clusterId, customStyles = DEFAULT_STYLE }: ClusterSourcesPropsType) => {
    const domainConfig = useSelector(selectDomainConfig, shallowEqual);
    const cluster = useSelector(selectClusterById(clusterId), shallowEqual);
    const topic = useSelector(selectTopicByClusterId(clusterId), shallowEqual);
    const alias = useSelector(selectProjectAlias);
    const resource = useSelector(
      selectClusterResource(clusterId),
      shallowEqual,
    );
    const editor = useSelector(selectClusterEditor(clusterId), shallowEqual);

    const isRamblerResource =
      typeof resource.id === 'number' &&
      RAMBLER_RESOURCES_IDS.includes(resource.id);
    const utm = isRamblerResource
      ? `utm_source=m${alias}_media&utm_medium=source&utm_campaign=self_promo&utm_content=m${alias}_media`
      : `utm_medium=source&utm_source=r${alias}`;

    const urlMoreLink = `${generateClusterUrl({
      clusterId,
      normalizedTitle: cluster?.normalizedTitle || '',
      domainConfig,
      topic,
      addDomain: false,
    })}items/`;

    const moreSourceCount = cluster?.resourcesCount
      ? cluster.resourcesCount - 1
      : 0;

    return (
      <div>
        {editor && isRamblerResource && (
          <EditorInfo
            utm={utm}
            editor={editor}
            withIcon={false}
            customStyles={customStyles}
          />
        )}

        {!editor && resource && (
          <SourceInfo
            utm={utm}
            resource={resource}
            urlMoreLink={urlMoreLink}
            moreSourceCount={moreSourceCount}
            styles={customStyles}
          />
        )}
      </div>
    );
  },
);
