import { getTextScaleFromCookies } from 'common/components/ClusterControl/components/TextScale/utils';

import {
  clusterSetTextScale,
  MAX_TEXT_SCALE_VALUE,
  MIN_TEXT_SCALE_VALUE,
} from '.';

/**
 * Инициализация шрифта из localStorage.
 *
 * Функцию необходимо обвернуть в dispatch.
 */
export const initTextScale = () => {
  const value = getTextScaleFromCookies({
    minValue: MIN_TEXT_SCALE_VALUE,
    maxValue: MAX_TEXT_SCALE_VALUE,
  });

  return clusterSetTextScale(value);
};
