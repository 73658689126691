import { BlockLengthType, InjectorType } from '@media-components/draft-parser';

import { checkInreadInsert } from 'common/utils/clusterContent/useParagraphsDesk';
import Banners from 'config/constants/banner/banners';
import { checkIsAdlessMaterial } from 'utils/checkIsPartnerMaterial';

import { bannerBlockGenerator } from '../../generators/bannerBlockGenerator';
import { checkCanInsertBanners, checkCanInsertBetween } from '../../utils';

const INREAD_INSERT_CHAR_COUNT = 2000;
const INREAD_STYLE = { marginBottom: '20px' };

type Props = {
  blocksLength: BlockLengthType;
  clusterId: CardData['id'];
  isLongread: boolean;
  isPaid?: boolean;
};

/**
 * Вживление баннера инрида (inpage/inread, баннер между параграфами).
 * @param blocks - блоки драфта;
 * @param blockLength - мапка с размерами блоков и колвом текста во всем приложении;
 * @param clusterId - id кластера;
 * @param isPaid - флаг партнерского материала;
 * @param isLongread - флаг, что это лонгрид.
 */
export const injectInread: InjectorType<Props> = ({
  blocks,
  blocksLength,
  clusterId,
  isLongread,
  isPaid,
}) => {
  const bannerIndex = blocks.findIndex(
    (draftBlock, index, originalBlocks) =>
      checkInreadInsert(blocksLength.fullLength, isLongread) &&
      // Проверка, что размер кластера относительно начала текста больше определенного максимума
      blocksLength.blocks[draftBlock.key]?.absoluteLength >
        INREAD_INSERT_CHAR_COUNT &&
      checkCanInsertBanners(originalBlocks, index) &&
      !checkIsAdlessMaterial(clusterId) &&
      checkCanInsertBetween(blocks, index) &&
      !isPaid,
  );

  if (bannerIndex === -1) {
    return blocks;
  }

  const adBlock = bannerBlockGenerator({
    name: Banners.Inread,
    isLazy: true,
    onRenderStyle: INREAD_STYLE,
  });

  // Вживляем блок после подходящего по условиям блока
  return [
    ...blocks.slice(0, bannerIndex + 1),
    adBlock,
    ...blocks.slice(bannerIndex + 1),
  ];
};
