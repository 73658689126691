import cn from 'classnames';
import React, { memo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { ClusterButtonsHeader } from 'common/components/ClusterButtonsHeader';
import { ClusterSources } from 'common/components/ClusterSources';
import { ExpertSignatures } from 'common/components/SourceBlock/components/ExpertSignatures';
import { useCopyModifier } from 'common/hooks/useCopyModifier';
import { useArticleFullTextShow } from 'common/hooks/useRcm';
import {
  selectClusterById,
  selectClusterExpertIds,
} from 'common/redux/commonData/entries/selectors';
import { selectExpertsByIds } from 'common/redux/commonData/experts/selectors';
import { selectManualTagIsMulticluster } from 'common/redux/commonData/manualTags/selectors';
import { selectTopicByClusterId } from 'common/redux/commonData/topics/selectors';
import { selectClusterPageTextScale } from 'common/redux/pages/cluster/selectors';
import { selectIsMobile } from 'common/redux/runtime/selectors';
import { SourceDate } from 'desktop/components/Cluster/SourceDate';
import { addNonBreakingSpaces } from 'utils/nonBreakingSpaces';

import s from './styles.module.css';

type ArticleHeaderPropsType = {
  clusterIndex: number;
  clusterId: ClusterData['id'];
};

/**
 * Шапка отдельной статьи
 * @param clusterId - id текущего кластера
 * @param clusterIndex - индекс текущего кластера в бесконечке
 */
export const ArticleHeader = memo(
  ({ clusterId, clusterIndex }: ArticleHeaderPropsType) => {
    const cluster = useSelector(selectClusterById(clusterId), shallowEqual);
    const textScaleValue = useSelector(selectClusterPageTextScale);
    const expertIds = useSelector(
      selectClusterExpertIds(clusterId),
      shallowEqual,
    );
    const experts = useSelector(selectExpertsByIds(expertIds), shallowEqual);
    const topicByClusterId = useSelector(
      selectTopicByClusterId(clusterId),
      shallowEqual,
    );
    const isMulticluster = useSelector(
      selectManualTagIsMulticluster(
        cluster?.manualTagIds,
        cluster?.displayType,
      ),
    );
    const isMobile = useSelector(selectIsMobile);
    const h1ref = useCopyModifier<HTMLHeadingElement>(
      cluster,
      topicByClusterId?.alias,
    );

    useArticleFullTextShow({
      clusterId,
      position: clusterIndex,
      ref: h1ref,
    });

    return (
      <header className={cn(s.root, isMobile && s.mobile)}>
        <h1
          ref={h1ref}
          className={cn(
            s.title,
            s[`title_${textScaleValue}`],
            isMobile && s.mobile,
            isMobile && s[`mobile_${textScaleValue}`],
          )}
          id="headline"
        >
          {addNonBreakingSpaces(cluster?.description || '', 2)}
        </h1>
        <div className={cn(s.container, isMobile && s.mobile)}>
          <div>
            <div className={s.sorceNDate}>
              {!isMulticluster && (
                <ClusterSources clusterId={clusterId} customStyles={s} />
              )}
              <SourceDate date={cluster?.publicationTime} customStyles={s} />
            </div>
            {!!expertIds?.length && (
              <ExpertSignatures
                experts={experts}
                withIcon={false}
                customStyles={s}
              />
            )}
          </div>
          <ClusterButtonsHeader
            clusterId={clusterId}
            clusterIndex={clusterIndex}
          />
        </div>
      </header>
    );
  },
);
