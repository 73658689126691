import { BlockElementPropsType } from '@media-components/draft-parser';
import React, { memo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { useDraftContext } from 'common/components/ClusterContentOnDraft/context';
import { ArticleMediaSectionDataType } from 'common/components/ClusterContentOnDraft/draftModifier/generators/articleMediaSectionBlockGenerator';
import { IncrementCountersTrigger } from 'common/components/IncrementCountersTrigger';
import { selectTopicByClusterId } from 'common/redux/commonData/topics/selectors';
import { selectClusterPageClusterFeed } from 'common/redux/pages/cluster/selectors';
import { ArticleMediaSection } from 'desktop/pages/Cluster/components/ArticleMediaSection';

/**
 * Компонент рендера блока драфта для медиа секции.
 */
export const ArticleMediaSectionBlock = memo(
  ({ data }: BlockElementPropsType<ArticleMediaSectionDataType, unknown>) => {
    const { pageId } = useDraftContext();

    const feedList = useSelector(selectClusterPageClusterFeed, shallowEqual);
    const clusterTopic = useSelector(
      selectTopicByClusterId(pageId),
      shallowEqual,
    );

    if (Number.isNaN(Number(data?.[0]?.clusterFeedListIndex)) || !pageId) {
      return null;
    }

    const { clusterFeedListIndex, hasGallery } = data![0];

    return (
      <>
        <IncrementCountersTrigger
          firstCluster={feedList?.[0] === pageId}
          clusterID={pageId}
          projectID={clusterTopic?.project_id}
        />
        <ArticleMediaSection
          clusterIndex={clusterFeedListIndex}
          clusterId={pageId}
          hasGallery={hasGallery}
        />
      </>
    );
  },
  (prev, next) =>
    prev?.data?.[0]?.clusterFeedListIndex ===
    next?.data?.[0]?.clusterFeedListIndex,
);
