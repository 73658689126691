import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useTop100Attribute } from 'common/hooks/useTop100Attribute';
import { selectProjectAlias } from 'common/redux/runtime/selectors';
import {
  getParagraphType,
  paragraphTypesList,
  injectMediaParams,
} from 'common/utils/clusterContent/getParagraphType';
import { getPureText } from 'utils/getPureText';
import { splitByParagraphs } from 'utils/splitByParagraphs';

import { PARAGRAPH_TYPE } from './config';
import { cutResource } from './cut/cutResource';
import { cutShort } from './cut/cutShort';
import { getArticleWithTags } from './getTaggedText';
import { replaceLinkTarget } from './replaceLinkTarget';
import { replaceOldTop100Markup } from './replaceOldTop100Markup';
import {
  ParagraphConfigType,
  ParagraphArr,
  CUT_TYPE,
  CUT_TOP100,
  UseParagraphsDeskType,
} from './typings';

export { CUT_TYPE, CUT_TOP100 };

/** Количество символов, после которых в конце параграфов вставляется баннер */
const ADCENTER_BANNER_OFFSET = 2000;
const INREAD_BANNER_OFFSET = 3000;
const INREAD_BANNER_OFFSET_LONG = 4500;
const INPAGE_BANNER_OFFSET = 4000;
const INPAGE_BANNER_OFFSET_LONG = 6000;

/**
 * Логика вставки баннеров - https://confluence.rambler-co.ru/pages/viewpage.action?pageId=39513126
 * Раздел "Материал"
 */

/**
 * Проверка на вставку Adcenter-баннера.
 * Длинна двух первых параграфов (https://jira.rambler-co.ru/browse/NEWS-6627)
 * Возврат логики (https://jira.rambler-co.ru/browse/NEWS-6996)
 * @param articleLength - длина статьи
 */
export const checkAdcenterInsert = (articleLength: number) =>
  articleLength >= ADCENTER_BANNER_OFFSET;

/**
 * Проверка на вставку Inread-баннера
 * @param articleLength - длина статьи
 * @param isLong - флаг что кластер лонгрид
 */
export const checkInreadInsert = (articleLength: number, isLong: boolean) =>
  articleLength > (isLong ? INREAD_BANNER_OFFSET_LONG : INREAD_BANNER_OFFSET);

/**
 * Проверка на вставку Inpage-баннера
 * @param articleLength - длина статьи
 * @param isLong - флаг что кластер лонгрид
 */
export const checkInpageInsert = (articleLength: number, isLong: boolean) =>
  articleLength > (isLong ? INPAGE_BANNER_OFFSET_LONG : INPAGE_BANNER_OFFSET);

const useCut = (
  paragraphsList: ParagraphConfigType[],
  cut?: CUT_TYPE,
): ParagraphArr => {
  switch (cut) {
    case CUT_TYPE.SHORT: {
      return cutShort(paragraphsList);
    }

    case CUT_TYPE.RESOURCE_CUT: {
      return cutResource(paragraphsList);
    }

    case CUT_TYPE.NO_CUT:

    // eslint-disable-next-line no-fallthrough
    default: {
      return {
        paragraphsListBeforeCut: [...paragraphsList],
        paragraphsListInCut: [],
      };
    }
  }
};

/**
 * Получение объект с массивами параграфов для вывода текста и баннеров
 * @param clusterBody - строковое представление тела кластера (cluster.body)
 * @param autotags - конфигурация вставляемых автотегов для тела кластера
 * @param draft – данные для разных блоков тела кластера
 * @param options.cut - тип применяемого CUT
 * @param options.isLong - флаг что кластер лонгрид
 * @param options.withFilterTags - флаг что нужно отфильтровать выводимые теги
 * @param options.isAppDesign - флаг вебвью версии
 */
export const useParagraphsDesk = ({
  clusterBody,
  autotags,
  draft = { blocks: [], entityMap: {} },
  options: { cut, isLong = false, withFilterTags, isAppDesign } = {},
}: UseParagraphsDeskType) => {
  const top100AttributeName = useTop100Attribute();

  const projectAlias = useSelector(selectProjectAlias);

  const paragraphsList = useMemo(
    () => {
      const { articleWithTags } = getArticleWithTags(
        clusterBody,
        autotags,
        draft,
        projectAlias,
        {
          isLongread: isLong,
          withFilterTags,
          isAppDesign,
          isMobile: false,
        },
      );

      return splitByParagraphs(articleWithTags);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const typedArticleBody = useMemo(() => {
    // Счетчик символов в тексте
    let articleLength = 0;

    const typedArticleParagraphs: ParagraphConfigType[] = paragraphsList.map(
      (text) => {
        const { type, config } = getParagraphType(text);
        let paragraphLength = 0;

        // Эмбеды и заголовки не участвуют в подсчете символов
        if (
          type !== PARAGRAPH_TYPE.EMBED &&
          type !== PARAGRAPH_TYPE.NO_INCREMENT_TAG
        ) {
          paragraphLength = getPureText(text).length;
          articleLength += paragraphLength;
        }

        const result: ParagraphConfigType = {
          paragraphLength,
          accumulatedLength: articleLength,
          text: type === PARAGRAPH_TYPE.MEDIA ? injectMediaParams(text) : text,
          type,
          config,
          Component: paragraphTypesList[type],
        };

        return result;
      },
    );

    return {
      typedArticleParagraphs,
      articleLength,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { typedArticleParagraphs, articleLength } = typedArticleBody;

  /** Проверка на вставку Adcenter-баннера */
  const isInsertAdcenter = useMemo(
    () => checkAdcenterInsert(articleLength),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  /** Проверка на вставку Inread-баннера */
  const isInsertInread = useMemo(
    () => checkInreadInsert(articleLength, isLong),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  /** Проверка на вставку Inpage-баннера */
  const isInsertInpage = useMemo(
    () => checkInpageInsert(articleLength, isLong),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // Меняем аттрибут топ100, так генерируется он слишком глубоко
  const replacedTop100Paragraphs = replaceOldTop100Markup(
    typedArticleParagraphs,
    top100AttributeName,
  );

  // Меняем значение аттрибута target, внешние ссылки _blank, ссылки на теги _self
  const replacedLinkTarget = replaceLinkTarget(replacedTop100Paragraphs);

  const paragraphs = useCut(replacedLinkTarget, cut);

  return {
    paragraphs,
    adcenter: isInsertAdcenter,
    inread: isInsertInread,
    inpage: isInsertInpage,
  };
};
