import React, { useEffect, useRef, useState, useCallback } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { selectRuntime } from 'common/redux/runtime/selectors';
import {
  getClusterEndlessScrollGoals,
  getClusterPercentScrollGoals,
  FULL_SCROLL_AFTER_READ_MORE,
} from 'common/utils/getClusterScrollGoals';
import { yaReachGoal } from 'utils/counters/yaMetrika';
import { COUNTER_ID } from 'utils/counters/yaMetrika/constants';
import {
  addObserver,
  decoratorScopeEntryCheck,
} from 'utils/intersectionObserverUtils';

type MetrikaRefType = React.MutableRefObject<HTMLDivElement | null>;

type MetrikaRefs =
  | 'metrikaRefEnd'
  | 'metrikaRefStart'
  | 'metrikaRefPart'
  | 'metrikaRefInCutEnd';

type ReturnType = Record<MetrikaRefs, MetrikaRefType>;

type UseClusterScrollPropsType = {
  cluster: ClusterData | undefined;
  clusterIndex: number;
};

const { WebMobile, Common, Portal } = COUNTER_ID;
const YA_METRIC_TYPES = [WebMobile, Common, Portal];

/**
 * Хук для тригера начала и конца скрола кластера
 * @param cluster - кластер
 * @param clusterIndex - индекс кластера при скроле
 */
export const useClusterScroll = ({
  cluster,
  clusterIndex,
}: UseClusterScrollPropsType): ReturnType => {
  const [checkStart, setCheckStart] = useState(false);
  const [checkEnd, setCheckEnd] = useState(false);
  const [checkPart, setCheckPart] = useState(false);
  const [checkInCutEnd, setCheckInCutEnd] = useState(false);

  const runtime = useSelector(selectRuntime, shallowEqual);

  const metrikaRefStart = useRef(null);
  const metrikaRefEnd = useRef(null);
  const metrikaRefPart = useRef(null);
  const metrikaRefInCutEnd = useRef(null);

  const clusterType = cluster?.isCommercial
    ? 'longread'
    : (cluster?.type as ClusterTypeType);

  const reachGoalsCallbacksExecutor = useCallback(
    (counters: COUNTER_ID[], target: string) => {
      counters.forEach((counter) => {
        yaReachGoal(runtime, counter, target);
      });
    },
    [runtime],
  );

  useEffect(() => {
    const endScroll = () => {
      setCheckEnd(true);

      reachGoalsCallbacksExecutor(
        YA_METRIC_TYPES,
        getClusterPercentScrollGoals(clusterType, 100),
      );
    };

    const endInCutScroll = () => {
      setCheckInCutEnd(true);

      reachGoalsCallbacksExecutor(
        YA_METRIC_TYPES,
        getClusterEndlessScrollGoals(clusterType, clusterIndex + 1),
      );

      reachGoalsCallbacksExecutor(
        YA_METRIC_TYPES,
        getClusterPercentScrollGoals(clusterType, FULL_SCROLL_AFTER_READ_MORE),
      );
    };

    const partScroll = () => {
      setCheckPart(true);

      reachGoalsCallbacksExecutor(
        YA_METRIC_TYPES,
        getClusterPercentScrollGoals(clusterType, 100),
      );
    };

    const startScroll = () => {
      setCheckStart(true);

      reachGoalsCallbacksExecutor(
        YA_METRIC_TYPES,
        getClusterEndlessScrollGoals(clusterType, clusterIndex + 1),
      );

      reachGoalsCallbacksExecutor(
        YA_METRIC_TYPES,
        getClusterPercentScrollGoals(clusterType, 0),
      );
    };

    let observerStartScroll: IntersectionObserver | null = null;

    const localCallbackStart = decoratorScopeEntryCheck(startScroll);

    observerStartScroll = addObserver(
      metrikaRefStart,
      (entry, observerLink) => {
        if (!checkStart) {
          localCallbackStart(entry, observerLink);
        }
      },
    );

    let observerPartScroll: IntersectionObserver | null = null;

    const localCallbackPart = decoratorScopeEntryCheck(partScroll);

    observerPartScroll = addObserver(metrikaRefPart, (entry, observer) => {
      if (!checkPart) {
        localCallbackPart(entry, observer);
      }
    });

    let observerEndScroll: IntersectionObserver | null = null;

    const localCallbackEnd = decoratorScopeEntryCheck(endScroll);

    observerEndScroll = addObserver(metrikaRefEnd, (entry, observer) => {
      if (!checkEnd) {
        localCallbackEnd(entry, observer);
      }
    });

    let observerCutEndScroll: IntersectionObserver | null = null;

    const localCallbackCutEnd = decoratorScopeEntryCheck(endInCutScroll);

    observerCutEndScroll = addObserver(
      metrikaRefInCutEnd,
      (entry, observer) => {
        if (checkPart && !checkInCutEnd) {
          localCallbackCutEnd(entry, observer);
        }
      },
    );

    return () => {
      if (observerEndScroll) {
        observerEndScroll.disconnect();
        observerEndScroll = null;
      }

      if (observerStartScroll) {
        observerStartScroll.disconnect();
        observerStartScroll = null;
      }

      if (observerPartScroll) {
        observerPartScroll.disconnect();
        observerPartScroll = null;
      }

      if (observerCutEndScroll) {
        observerCutEndScroll.disconnect();
        observerCutEndScroll = null;
      }
    };
  }, [
    checkStart,
    checkPart,
    checkEnd,
    checkInCutEnd,
    reachGoalsCallbacksExecutor,
    clusterType,
    clusterIndex,
  ]);

  return {
    metrikaRefStart,
    metrikaRefEnd,
    metrikaRefPart,
    metrikaRefInCutEnd,
  };
};
