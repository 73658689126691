import { InjectorType, BlockTypes } from '@media-components/draft-parser';

import { checkAdcenterInsert } from 'common/utils/clusterContent/useParagraphsDesk';
import Banners from 'config/constants/banner/banners';
import { CLUSTER_TYPE } from 'config/constants/cluster';
import {
  checkIsAdlessMaterial,
  checkIsPartnerMaterial,
} from 'utils/checkIsPartnerMaterial';

import {
  bannerBlockGenerator,
  BANNER_REPLACEMENT_SLOT,
} from '../../generators/bannerBlockGenerator';
import {
  checkCanInsertBanners,
  checkCanInsertBetween,
  checkIsAtomicLikeBlock,
} from '../../utils';

import s from './styles.module.css';

const ADCENTER_STYLE = { marginBottom: '20px' };

type Props = {
  clusterId: CardData['id'];
  articleLength: number;
  resourceId: ClusterResourceType['id'];
  clusterType: CardData['type'];
  isPaid?: boolean;
};

/**
 * Вживление в тело драфта блока адцентра.
 * https://confluence.rambler-co.ru/pages/viewpage.action?pageId=39513126
 * @param blocks - тело драфта;
 * @param articleLength - длина статьи в символах;
 * @param clusterId - id кластера;
 * @param resourceId - id источника кластера;
 * @param isPaid - флаг партнерского материала;
 * @param clusterType - тип кластера;
 */
export const injectAdcenter: InjectorType<Props> = ({
  blocks,
  articleLength,
  clusterId,
  resourceId,
  clusterType,
  isPaid,
}) => {
  const findSecondParagraph = () => {
    // Блок адцентра вставляется после второго параграфа
    let paragraphCount = 0;

    return (
      draftBlock: RawDraftContentBlock,
      index: number,
      originalBlocks: RawDraftContentState['blocks'],
    ) => {
      // Для любых непараграфов увелиичваем счетчик пройденных
      if (!checkIsAtomicLikeBlock(draftBlock.type as BlockTypes))
        paragraphCount += 1;

      return (
        checkAdcenterInsert(articleLength) &&
        checkCanInsertBanners(originalBlocks, index) &&
        checkCanInsertBetween(originalBlocks, index) &&
        !checkIsAdlessMaterial(clusterId) &&
        // не выводим рекламу и видео дня для партнерских материалов https://jira.rambler-co.ru/browse/NEWS-10457
        !checkIsPartnerMaterial(resourceId) &&
        !isPaid &&
        /*
          Как только было насчитано хотя бы два параграфа и место удовлетворяет вставке,
          то встраиваем блок.
          */
        paragraphCount >= 3
      );
    };
  };

  const bannerIndex = blocks.findIndex(findSecondParagraph());

  if (bannerIndex === -1) {
    return blocks;
  }

  const adBlock = bannerBlockGenerator({
    name: Banners.Adcenter,
    isLazy: true,
    onRenderStyle: ADCENTER_STYLE,
    className: s.adcenter,
    bannerReplacementSlot:
      CLUSTER_TYPE.video !== clusterType
        ? BANNER_REPLACEMENT_SLOT.VideoAdIframe
        : undefined,
    clusterId,
  });

  // Вживляем блок после подходящего по условиям блока
  return [
    ...blocks.slice(0, bannerIndex + 1),
    adBlock,
    ...blocks.slice(bannerIndex + 1),
  ];
};
