import { blockGenerator } from '@media-components/draft-parser';
import _uniqueid from 'lodash.uniqueid';

import { CUSTOM_BLOCK_TYPE } from 'common/components/ClusterContentOnDraft/typings';

export type ResourceRelatedBlockDataType = {
  /** Id кластера. */
  clusterId: ClusterData['id'];
  /** Id источника. */
  resourceId: ClusterData['resourceId'];
};

/**
 * Блок новостей рнет источника.
 * @see ResourceRelatedBlockDataType ;
 */
export const resourceRelatedBlockGenerator = ({
  clusterId,
  resourceId,
}: ResourceRelatedBlockDataType) =>
  blockGenerator({
    key: `${CUSTOM_BLOCK_TYPE.ResourceRelated}_${_uniqueid()}`,
    type: CUSTOM_BLOCK_TYPE.ResourceRelated,
    data: {
      clusterId,
      resourceId,
    },
  });
