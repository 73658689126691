import cn from 'classnames';
import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useAnalytics } from 'common/hooks/useAnalytics';
import {
  fontEnlargeButtonAnalyticsConfig,
  fontReduceButtonAnalyticsConfig,
} from 'common/hooks/useAnalytics/configs';
import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';
import {
  MAX_TEXT_SCALE_VALUE,
  MIN_TEXT_SCALE_VALUE,
  TextScaleValueType,
} from 'common/redux/pages/cluster';
import { initTextScale } from 'common/redux/pages/cluster/utils';
import { MinusIcon, PlusIcon } from 'icons';

import { clearTextScalePlug } from './utils';

type TextScalePropsType = {
  textScaleValue: TextScaleValueType;
  textScaleUp: React.MouseEventHandler<HTMLButtonElement>;
  textScaleDown: React.MouseEventHandler<HTMLButtonElement>;
  customStyles?: StylesType;
  textScaleMax?: number;
  textScaleMin?: number;
  isMobile?: boolean;
};

/**
 * Панель управления размером шрифта кластера
 * @param textScaleValue - Значение масштаба шрифта
 * @param textScaleUp - Увеличение размера шрифта
 * @param textScaleDown - Уменьшение размера шрифта
 * @param customStyles - объект стилизации компонента
 * @param textScaleMax - максимальное увеличение текста
 * @param textScaleMin - минимальное увеличение текста
 * @param isMobile - флаг мобильной версии
 */
export const TextScale = memo(
  ({
    textScaleValue,
    textScaleUp,
    textScaleDown,
    customStyles = {},
    textScaleMax = MAX_TEXT_SCALE_VALUE,
    textScaleMin = MIN_TEXT_SCALE_VALUE,
    isMobile,
  }: TextScalePropsType) => {
    const { reachClick: reachEnlargeButtonClick } = useAnalytics(
      fontEnlargeButtonAnalyticsConfig,
      isMobile,
    );
    const { reachClick: reachReduceButtonClick } = useAnalytics(
      fontReduceButtonAnalyticsConfig,
      isMobile,
    );
    const dispatch = useDispatch();

    const top100AttributeFontUp = useTop100AttributeWithValue('font_up');
    const top100AttributeFontDown = useTop100AttributeWithValue('font_down');

    useEffect(() => {
      dispatch(initTextScale());
      clearTextScalePlug();
    }, [dispatch]);

    const handleScaleUpClick = useCallback(
      (e: React.MouseEvent<HTMLButtonElement>) => {
        textScaleUp(e);

        if (isMobile) {
          reachEnlargeButtonClick();
        }
      },
      [isMobile, reachEnlargeButtonClick, textScaleUp],
    );

    const handleScaleDownClick = useCallback(
      (e: React.MouseEvent<HTMLButtonElement>) => {
        textScaleDown(e);

        if (isMobile) {
          reachReduceButtonClick();
        }
      },
      [isMobile, reachReduceButtonClick, textScaleDown],
    );

    return (
      <div className={customStyles.textScaleWrapper}>
        <button
          type="button"
          disabled={textScaleValue === textScaleMax}
          className={cn(customStyles.textScaleBtn, {
            [customStyles.textScaleBtn_disabled]:
              textScaleValue === textScaleMax,
          })}
          onClick={handleScaleUpClick}
          aria-label="Увеличить шрифт"
          {...top100AttributeFontUp}
        >
          <PlusIcon className={customStyles.icon} />
        </button>
        <button
          type="button"
          className={cn(customStyles.textScaleBtn, {
            [customStyles.textScaleBtn_disabled]:
              textScaleValue === textScaleMin,
          })}
          disabled={textScaleValue === textScaleMin}
          onClick={handleScaleDownClick}
          aria-label="Уменьшить шрифт"
          {...top100AttributeFontDown}
        >
          <MinusIcon className={customStyles.icon} />
        </button>
      </div>
    );
  },
);
