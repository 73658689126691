import { BlockElementPropsType } from '@media-components/draft-parser';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { Ad } from 'common/components/Ad';
import { useDraftContext } from 'common/components/ClusterContentOnDraft/context';
import {
  BannerDataType,
  BANNER_REPLACEMENT_SLOT,
} from 'common/components/ClusterContentOnDraft/draftModifier/generators/bannerBlockGenerator';
import { selectIsMobile } from 'common/redux/runtime/selectors';

import { VideoAdIframeWrapper } from './components/VideoAdIframe';

/**
 * Функция генерации блока вставки в рекламу внутри текста кластера.
 * @param bannerReplacementSlot - наименование блока вставки;
 * @param clusterId - id кластера, для которого рисуется блок вставки.
 */
const getBannerReplacementElement = (
  bannerReplacementSlot: BANNER_REPLACEMENT_SLOT | undefined,
  clusterId: CardData['id'],
) => {
  if (bannerReplacementSlot === BANNER_REPLACEMENT_SLOT.VideoAdIframe) {
    return <VideoAdIframeWrapper clusterId={clusterId} />;
  }

  return null;
};

/**
 * Компонент рендера блока рекламы.
 * @param props.data.name - наименование баннера;
 * @param props.data.isLazy - флаг ленивой загрузки баннера;
 * @param props.data.onRenderStyle - дополнительные стили для баннера.
 */
export const Banner = memo(
  ({ data, styles }: BlockElementPropsType<BannerDataType, unknown>) => {
    const { puids } = useDraftContext();
    const isMobile = useSelector(selectIsMobile);

    if (!data?.[0]?.name) {
      return null;
    }

    const {
      name,
      clusterId,
      isLazy,
      onRenderStyle,
      bannerReplacementSlot,
      className,
    } = data[0];

    const bannerReplacementElement = getBannerReplacementElement(
      bannerReplacementSlot,
      String(clusterId),
    );

    return (
      <>
        {isMobile && (
          <div className={styles?.readAfter}>
            Продолжение истории после рекламы
          </div>
        )}
        <Ad
          name={name}
          puids={puids}
          isLazy={isLazy}
          loadedStyle={onRenderStyle}
          bannerReplacementSlot={bannerReplacementElement}
          className={className}
        />
      </>
    );
  },
  (prev, next) => prev?.data?.[0]?.name === next?.data?.[0]?.name,
);
