import { BlockElementPropsType } from '@media-components/draft-parser';
import React, { memo } from 'react';

import { useDraftContext } from 'common/components/ClusterContentOnDraft/context';
import { RelatedDir1 } from 'mobile/components/Related';

/**
 * Компонент с последними новостями источника не рнет.
 * @param styles – стили блока
 */
export const Related = memo(
  ({ styles }: BlockElementPropsType<unknown, unknown>) => {
    const { pageId } = useDraftContext();

    return (
      <div className={styles?.root}>
        <RelatedDir1 clusterId={pageId} />
      </div>
    );
  },
);
