// TODO(NEWS-0000): разобрать даты получше, понять можно ли работать через new Date()

/**
 * В файле собраны вспомагательные функции для работы с датами через строки (без new Date())
 *
 * Почему без new Date?
 *
 * Проблема 1: у нас могут быть разные часовые пояса на сервере и на клиенте. Как это узнать?
 * Вывести в консоль new Date().getTimezoneOffset() – на сервере, будет равняться 0.
 * На клиенте будет зависеть от часового пояса, если пользователь в Москве, то будет -180.
 * Изза разных оффсетов дата/время могут различаться
 * Пример: кластер https://news.rambler.ru/world/49538956-mir-uzhe-pereshel-chertu-karibskogo-krizisa/
 * Отображается дата 19 октября. Если нажать правой кнопкой мыши и выбрать "Просмотреть код страницы", то вместо 19 октября в html будет 18 октября
 * Также можно навести курсор на ссылку, она ведет на новости за 18 октября вместо 19го
 *
 * Проблема 2: если вывести в консоль new Date('2022-10-19T00:07:15+03:00'), то время в дате отображается 00:07
 * Если вывести в консоль через fecha – formatter(new Date('2022-10-19T00:07:15+03:00'), 'clusterDate'), то отображается время 12:07
 *
 * Тк дата кластера всегда должна отображаться по Москве, независимо от того, где находится пользователь,
 * сделано через строки вместо new Date()
 */

const MONTHES = [
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря',
];

/**
 * Разбивает строку даты на составляющие и возвращет объект
 * @param fullDate - дата, пример: '2022-10-19T00:07:15+03:00'
 */
export const getMoscowDate = (fullDate: string) => {
  if (!fullDate) return {};
  const [calendarDate, fullTime] = fullDate.split('T');
  const [year, month, day] = calendarDate.split('-');
  const [hours, minutes, seconds] = fullTime.split('+')[0].split(':');

  return {
    year,
    month,
    day,
    hours,
    minutes,
    seconds,
    monthWord: MONTHES[Number(month) - 1],
  };
};

/**
 * Создает дату кластера в формате "дд %месяц% (год опционально), чч:мм" по МСК
 * @param fullDate - дата, пример: '2022-10-19T00:07:15+03:00'
 */
export const getClusterDate = (fullDate: string) => {
  const { day, monthWord, year, hours, minutes } = getMoscowDate(fullDate);
  const currentYear = new Date().getFullYear();

  // если год совпадает с текущим, год не выводим
  return Number(year) < currentYear
    ? `${day} ${monthWord} ${year}, ${hours}:${minutes}`
    : `${day} ${monthWord}, ${hours}:${minutes}`;
};

/**
 * Создает дату со слэшами /2022/10/19/, используется для ссылок
 * @param fullDate - дата, пример: '2022-10-19T00:07:15+03:00'
 */
export const getDateWithSlash = (fullDate: string) => {
  const { day, month, year } = getMoscowDate(fullDate);

  return `/${year}/${month}/${day}/`;
};

/**
 * Создает дату в формате "дд %месяц%"
 * @param fullDate - дата, пример: '2022-10-19T00:07:15+03:00'.
 */
export const getDayAndMonth = (fullDate: string) => {
  const { day, monthWord } = getMoscowDate(fullDate);

  return `${day} ${monthWord}`;
};
