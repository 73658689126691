/** Тут будут конфиги для аналитики */

import { COUNTER_ID } from 'utils/counters/yaMetrika/constants';

import { AnalyticsConfigType } from '.';

export const fontReduceButtonAnalyticsConfig: AnalyticsConfigType = {
  counters: [COUNTER_ID.Portal, COUNTER_ID.Common],
  showTarget: 'mob_cluster_font_reduce_button_show',
  clickTarget: 'mob_cluster_font_reduce_button_click',
};

export const fontEnlargeButtonAnalyticsConfig: AnalyticsConfigType = {
  counters: [COUNTER_ID.Portal, COUNTER_ID.Common],
  showTarget: 'mob_cluster_font_enlarge_button_show',
  clickTarget: 'mob_cluster_font_enlarge_button_click',
};

export const ttsButtonAnalyticsConfig: AnalyticsConfigType = {
  counters: [COUNTER_ID.Portal, COUNTER_ID.Common],
  showTarget: 'mob_cluster_tts_button_show',
  clickTarget: 'mob_cluster_tts_button_click',
};

export const shareButtonAnalyticsConfig: AnalyticsConfigType = {
  counters: [COUNTER_ID.Portal, COUNTER_ID.Common],
  showTarget: 'mob_cluster_share_button_show',
  clickTarget: 'mob_cluster_share_button_click',
};

export const backButtonAnalyticsConfig: AnalyticsConfigType = {
  counters: [COUNTER_ID.Portal, COUNTER_ID.Common],
  showTarget: 'mob_cluster_back_button_show',
  clickTarget: 'mob_cluster_back_button_click',
};

export const topicButtonAnalyticsConfig: AnalyticsConfigType = {
  counters: [COUNTER_ID.Portal, COUNTER_ID.Common],
  showTarget: 'mob_cluster_topic_button_show',
  clickTarget: 'mob_cluster_topic_button_click',
};

export const personLinkAnalyticsConfig: AnalyticsConfigType = {
  counters: [COUNTER_ID.Portal, COUNTER_ID.Common],
  showTarget: 'mob_cluster_with_person_link_show',
};

export const authorLinkAnalyticsConfig: AnalyticsConfigType = {
  counters: [COUNTER_ID.Portal, COUNTER_ID.Common],
  showTarget: 'mob_cluster_author_link_show',
  clickTarget: 'mob_cluster_author_link_click',
};

export const expertLinkAnalyticsConfig: AnalyticsConfigType = {
  counters: [COUNTER_ID.Portal, COUNTER_ID.Common],
  showTarget: 'mob_cluster_expert_link_show',
  clickTarget: 'mob_cluster_expert_link_click',
};
