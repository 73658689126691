import cn from 'classnames';
import React, { memo } from 'react';

import { MetaMaxVideoPreview } from 'common/components/MetaMaxVideoPreview';
import { VideoPlayer } from 'common/components/VideoPlayer';
import { PuidsType } from 'config/constants/common';

import s from './styles.module.css';

type VideoAdIframePropsType = {
  embedCode: string;
  className?: string;
  puids: PuidsType | undefined;
  clusterId: CardData['id'];
  clusterUrl: string;
};

/**
 * Компонент вставки рекламного видео
 * @param embedCode - код вставки рекламного видео
 * @param className - кастомные классы
 * @param puids - рекламные пуиды
 * @param clusterId - id кластера
 * @param clusterUrl - URL кластера, в который встроен плеер
 */
export const VideoAdIframe = memo(
  ({
    embedCode,
    className,
    puids,
    clusterId,
    clusterUrl,
  }: VideoAdIframePropsType) => (
    <div className={cn(s.container, className)}>
      <MetaMaxVideoPreview />
      <div className={s.title} data-test="day-video-title">
        Видео дня
      </div>
      <VideoPlayer
        embedCode={embedCode}
        isBroadcast={false}
        puids={puids}
        playerId={`cluster-video-ad-${clusterId}`}
        clusterId={clusterId}
        clusterUrl={clusterUrl}
        isAd
      />
    </div>
  ),
);
