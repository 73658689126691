import React, { memo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { selectClusterById } from 'common/redux/commonData/entries/selectors';
import { selectClusterPageTextScale } from 'common/redux/pages/cluster/selectors';
import { selectRuntime } from 'common/redux/runtime/selectors';
import { CLUSTER_TYPE } from 'config/constants/cluster';
import { PuidsType } from 'config/constants/common';
import { ClusterGallery } from 'mobile/components/ClusterGallery';
import { ClusterImage } from 'mobile/components/ClusterImage';
import { ClusterVideo } from 'mobile/components/ClusterVideo';

import stylesImage from './styles.image.css';

type MediaSectionPropsType = {
  clusterId: CardData['id'] | undefined;
  clusterIndex?: number;
  puids: PuidsType | undefined;
};

/**
 * Картинка/галерея/видео, в зависимости от типа кластера.
 * @param clusterId - id кластера;
 * @param clusterIndex - индекс кластера;
 * @param puids - рекламные пуиды.
 */
export const MediaSection = memo(
  ({ clusterId, clusterIndex = 0, puids }: MediaSectionPropsType) => {
    const runtime = useSelector(selectRuntime, shallowEqual);
    const cluster = useSelector(selectClusterById(clusterId), shallowEqual);
    const textScaleValue = useSelector(selectClusterPageTextScale);

    if (cluster?.type === CLUSTER_TYPE.video && cluster?.video?.embedCode) {
      return (
        <ClusterVideo
          cluster={cluster}
          puids={puids}
          clusterIndex={clusterIndex}
        />
      );
    }

    if (cluster?.type === CLUSTER_TYPE.photo) {
      return <ClusterGallery cluster={cluster} clusterIndex={clusterIndex} />;
    }

    if (cluster?.image?.url) {
      return (
        <ClusterImage
          runtime={runtime}
          cluster={cluster}
          textScaleValue={textScaleValue}
          styles={stylesImage}
          noImage={cluster.displayType === 'digest'}
        />
      );
    }

    return null;
  },
);
