import cn from 'classnames';
import React, { memo } from 'react';

import { Ad } from 'common/components/Ad';
import { selectIsHumanCenteredStrategyStateActivated } from 'common/redux/runtime/selectors';
import Banners from 'config/constants/banner/banners';
import { PuidsType } from 'config/constants/common';
import { useAppSelector } from 'store/hooks';

type CarouselSlideBannerPropsType = {
  puids: PuidsType | undefined;
  styles: StylesType;
};

/**
 * Слайд с баннером.
 * @param puids - объект рекламных пуидов;
 * @param styles - стили компонента.
 */
export const CarouselSlideBanner = memo(
  (props: CarouselSlideBannerPropsType) => {
    const forceRedesign = useAppSelector(
      selectIsHumanCenteredStrategyStateActivated,
    );
    const { puids, styles } = props;

    return (
      <>
        <div
          className={cn(
            styles.bannerWrapper,
            forceRedesign && styles.bannerWrapperRedesign,
          )}
        >
          <Ad
            name={Banners.Ingallery}
            puids={puids}
            className={styles.ad}
            withoutMarginBottom
          />
        </div>
        <p className={styles.bannerText}>
          Листайте дальше, чтобы пропустить рекламу
        </p>
      </>
    );
  },
);
