import React, { memo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { Video } from 'common/components/VideoDesk';
import {
  selectClusterById,
  selectClusterPuids,
} from 'common/redux/commonData/entries/selectors';
import { selectTopicByClusterId } from 'common/redux/commonData/topics/selectors';
import { selectClusterPageTextScale } from 'common/redux/pages/cluster/selectors';
import { CLUSTER_TYPE } from 'config/constants/cluster';
import { Image } from 'desktop/pages/Cluster/components/ImageDesk';

import stylesVideo from './components/Video/styles.module.css';

type ArticleMediaSectionPropsType = {
  clusterId: ClusterData['id'];
  clusterIndex: number;
  hasGallery?: boolean;
};

/**
 * Картинка/галерея/видео, в зависимости от типа кластера
 * @param clusterId - id кластера
 * @param clusterIndex - индекс кластера в бесконечном скролле
 * @param hasGallery - флаг что кластер с галереей
 * @returns заглавный блок фото/видео кластера
 */
export const ArticleMediaSection = memo(
  ({
    clusterId,
    clusterIndex,
    hasGallery = false,
  }: ArticleMediaSectionPropsType) => {
    const cluster = useSelector(selectClusterById(clusterId), shallowEqual);
    const textScaleValue = useSelector(selectClusterPageTextScale);
    const topic = useSelector(selectTopicByClusterId(clusterId), shallowEqual);
    const puids = useSelector(selectClusterPuids(clusterId), shallowEqual);

    if (cluster?.type === CLUSTER_TYPE.video && cluster?.video?.embedCode) {
      return (
        <Video
          cluster={cluster}
          topic={topic}
          clusterIndex={clusterIndex}
          styles={stylesVideo}
          puids={puids}
        />
      );
    }

    if (!hasGallery && cluster?.image?.url) {
      return (
        <Image
          cluster={cluster}
          textScaleValue={textScaleValue}
          noImage={cluster?.displayType === 'digest'}
        />
      );
    }

    return null;
  },
);
