import React, { memo } from 'react';

import { TagListLayout } from './components/TagListLayout';
import { useTagList } from './utils/useTagList';
import { useTagsButtonVisibilityChanger } from './utils/useTagsButtonVisibilityChanger';

type TagListPropsType = {
  clusterId: CardData['id'];
  styles?: StylesType;
};

/**
 * Обертка над шаблоном тегов для редизайна десктопного кластера
 * @param clusterId - id кластера для поиска тегов
 * @param styles - стили
 */
export const TagList = memo(({ clusterId, styles }: TagListPropsType) => {
  const tags = useTagList(clusterId);

  const { outerTagWrapperRef, innerTagWrapperRef, isTagsButtonVisible } =
    useTagsButtonVisibilityChanger();

  return (
    <TagListLayout
      markers={tags}
      styles={styles}
      outerTagWrapperRef={outerTagWrapperRef}
      isTagsButtonVisible={isTagsButtonVisible}
      innerTagWrapperRef={innerTagWrapperRef}
      clusterId={`${clusterId}`}
    />
  );
});
