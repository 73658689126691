import { useEffect, useRef, useState } from 'react';

/**
 * Хук для сравнения высоты двух контейнеров.
 * В зависимости от совпадения/не совпадения высот возвращает булевое значение false/true соответсвтенно для показа кнопки
 * @returns
 * - outerTagWrapperRef - ref для внешнего контейнера тэгов
 * - innerTagWrapperRef - ref для внутреннего контейнера тэгов
 * - isTagsButtonVisible - видно/не видно кнопку разворачивания тегов
 */

export const useTagsButtonVisibilityChanger = () => {
  const outerTagWrapperRef = useRef<HTMLDivElement | null>(null);
  const innerTagWrapperRef = useRef<HTMLDivElement | null>(null);
  const [isTagsButtonVisible, setIsTagsButtonVisible] = useState(false);

  useEffect(() => {
    if (outerTagWrapperRef.current && innerTagWrapperRef.current) {
      const heightOfTagWrapper =
        outerTagWrapperRef.current.getBoundingClientRect().height;
      const heightOfTag =
        innerTagWrapperRef.current.getBoundingClientRect().height;

      setIsTagsButtonVisible(heightOfTagWrapper !== heightOfTag);
    }
  }, [outerTagWrapperRef, innerTagWrapperRef]);

  return { outerTagWrapperRef, innerTagWrapperRef, isTagsButtonVisible };
};
