import cn from 'classnames';
import React, { memo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { selectResourceById } from 'common/redux/commonData/resources/selectors';
import { NEWS_PROJECT_ALIASES } from 'config/constants/projects/constants';
import { checkCorrectUrl } from 'utils/checkCorrectUrl';
import { getUrlWithUtmParams } from 'utils/getUrlWithUtmParams';
import { addInstBanComment } from 'utils/socialsBan/addBanComment';
import { checkIncludeInst } from 'utils/socialsBan/checkIncludeInst';
import { textCroppingByWord } from 'utils/textCroppingByWord';

type ImageDescriptionPropsType = {
  clusterImage: ClusterData['image'];
  clusterResourceId: ClusterData['resourceId'];
  projectAlias: NEWS_PROJECT_ALIASES;
  textScaleValue?: number;
  withPhoto?: boolean;
  styles?: { [className: string]: string };
  cropLength?: number;
};

/**
 * Главное фото кластера и ссылка на его источник
 * @param clusterImage - объект с данными для изображения кластера
 * @param clusterResourceId - id источника кластера
 * @param projectAlias - alias проекта
 * @param textScaleValue - значение масштабирования текста
 * @param withPhoto - нужна ли подпись "Фото"
 * @param styles - стили
 * @param cropLength - длина текста для обрезания
 */
export const ImageDescription = memo(
  ({
    clusterImage,
    clusterResourceId,
    projectAlias,
    textScaleValue,
    withPhoto = true,
    styles,
    cropLength,
  }: ImageDescriptionPropsType) => {
    const description = clusterImage?.description?.trim();

    const resource = useSelector(
      selectResourceById(clusterResourceId),
      shallowEqual,
    );

    const { url: imageSourceUrl, title: imageSourceTitle } =
      clusterImage?.source || {};
    const { url: resourceUrl = '', title: resourceTitle = '' } = resource ?? {};

    const isImageSourceEmpty = !imageSourceTitle && !imageSourceUrl;

    /** заголовок источника изображения */
    const imageSourceSignTitle = isImageSourceEmpty
      ? resourceTitle || resourceUrl
      : imageSourceTitle || imageSourceUrl;

    if (!imageSourceSignTitle) return null;

    /** url источника изображения */
    const imageSourceSignUrl = isImageSourceEmpty
      ? resourceUrl || resourceTitle
      : imageSourceUrl || imageSourceTitle;

    const hasSpace = imageSourceSignTitle?.match(/\s/) || '';
    const isRamblerImageSource = imageSourceSignUrl?.includes('rambler.ru');
    const cropText = addInstBanComment(
      cropLength
        ? textCroppingByWord({
            str: imageSourceSignTitle,
            maxLength: cropLength,
          })
        : imageSourceSignTitle,
    );

    const modifiedUrl = isRamblerImageSource
      ? getUrlWithUtmParams({
          url: imageSourceSignUrl,
          params: { medium: 'source', source: `r${projectAlias}` },
        })
      : imageSourceSignUrl;

    return (
      <figcaption
        className={cn(
          styles?.imageDescription,
          styles && {
            [styles[`imageDescription_${textScaleValue}`]]: textScaleValue,
            [styles.withDots]: !hasSpace && !checkIncludeInst,
          },
        )}
      >
        {!!description && (
          <span className={styles?.imageTitle}>{description}</span>
        )}
        {withPhoto && (
          <>
            {/* Если есть корректная ссылка на источник вставляем через тэг а, если нету – то обычным текстом */}
            {imageSourceSignUrl && checkCorrectUrl(imageSourceSignUrl) ? (
              <a
                className={styles?.imageDescriptionLink}
                aria-label={`Перейти на страницу источника ${cropText}`}
                href={modifiedUrl}
                target="_blank"
                rel={`noopener noreferrer${
                  isRamblerImageSource ? '' : ' nofollow'
                }`}
              >
                ©&nbsp;{cropText}
              </a>
            ) : (
              `©\xa0${cropText}`
            )}
          </>
        )}
      </figcaption>
    );
  },
);
