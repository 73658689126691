import React, { memo, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { ClusterImageObject } from 'common/components/schemaOrg/ImageObject/ClusterImageObject';
import { VideoObjectSchema } from 'common/components/schemaOrg/VideoObject';
import { VideoInline } from 'common/components/VideoInline';
import { VideoPlayer, VIDEO_DATA_TYPE } from 'common/components/VideoPlayer';
import { selectTopicById } from 'common/redux/commonData/topics/selectors';
import { selectDomainConfig } from 'common/redux/runtime/selectors';
import { checkIsNewPlayer } from 'common/utils/checkIsVideoPlatform';
import { PuidsType } from 'config/constants/common';
import { generateClusterUrl } from 'utils/urlGenerator';

import s from './styles.module.css';

type ClusterVideoPropsType = {
  cluster: ClusterData;
  styles?: StylesType;
  puids: PuidsType | undefined;
  clusterIndex: number;
};

/**
 * Главное видео кластера с разметкой schema.org
 * @param cluster - объект данных кластера
 * @param styles - стили компонента
 * @param puids - рекламные пуиды
 * @param clusterIndex - индекс кластера
 */
export const ClusterVideo = memo(
  ({ cluster, styles = s, puids, clusterIndex }: ClusterVideoPropsType) => {
    const domainConfig = useSelector(selectDomainConfig, shallowEqual);
    const topic = useSelector(
      selectTopicById(cluster.mainTopicId),
      shallowEqual,
    );

    const {
      video: { embedCode = '', videoData },
      id: clusterId,
      normalizedTitle,
    } = cluster;

    const clusterUrl = useMemo(
      () =>
        generateClusterUrl({ domainConfig, normalizedTitle, clusterId, topic }),
      [domainConfig, normalizedTitle, clusterId, topic],
    );

    const isEagleplatformVideo = checkIsNewPlayer(embedCode);

    const isBroadcast = videoData?.type === VIDEO_DATA_TYPE.broadcast;

    return (
      <div className={styles.videoWrapper}>
        <ClusterImageObject cluster={cluster} />
        <VideoObjectSchema cluster={cluster} />
        {isEagleplatformVideo ? (
          <VideoPlayer
            embedCode={embedCode}
            puids={puids}
            playerId={`cluster-${cluster.id}-${clusterIndex}`}
            isBroadcast={isBroadcast}
            clusterId={clusterId}
            clusterUrl={clusterUrl}
          />
        ) : (
          <VideoInline
            clusterIndex={clusterIndex}
            styles={styles}
            embedCode={embedCode}
            clusterUrl={clusterUrl}
          />
        )}
      </div>
    );
  },
);
