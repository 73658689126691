import React, { memo, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { VideoAdIframe } from 'common/components/VideoAdIframe';
import { selectVideoAd } from 'common/redux/appController/selectors';
import {
  selectClusterById,
  selectClusterMainTopic,
  selectClusterPuids,
} from 'common/redux/commonData/entries/selectors';
import { selectClusterPageClusterFeed } from 'common/redux/pages/cluster/selectors';
import { selectDomainConfig } from 'common/redux/runtime/selectors';
import { generateClusterUrl } from 'utils/urlGenerator';

type VideoAdIframeWrapperPropsType = {
  clusterId: CardData['id'];
};

/**
 * Компонент обертка над компонентом отрисовки Видео дня.
 * Уменьшает количество пропсов.
 * @param props.clusterId - id кластера, внутри которого рисуется Видео дня.
 */
export const VideoAdIframeWrapper = memo(
  ({ clusterId }: VideoAdIframeWrapperPropsType) => {
    const videoAd = useSelector(selectVideoAd, shallowEqual);
    const feedList = useSelector(selectClusterPageClusterFeed, shallowEqual);
    const firstCluster = useSelector(
      selectClusterById(feedList[0]),
      shallowEqual,
    );
    const topic = useSelector(
      selectClusterMainTopic(feedList[0]),
      shallowEqual,
    );
    const puids = useSelector(selectClusterPuids(clusterId), shallowEqual);
    const domainConfig = useSelector(selectDomainConfig, shallowEqual);

    const clusterInFeedList = feedList.includes(clusterId);

    const clusterUrl = useMemo(() => {
      if (!firstCluster) return '';

      const { id, normalizedTitle } = firstCluster;

      return generateClusterUrl({
        clusterId: id,
        normalizedTitle,
        topic,
        domainConfig,
      });
    }, [domainConfig, firstCluster, topic]);

    if (!clusterInFeedList || !firstCluster) {
      return null;
    }

    return (
      <VideoAdIframe
        embedCode={videoAd.embedCode}
        puids={puids}
        clusterId={clusterId}
        clusterUrl={clusterUrl}
      />
    );
  },
);
