import cn from 'classnames';
import React, { memo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { RecommendedClustersType } from 'common/components/Carousel/types';
import { RamblerImage } from 'common/components/RamblerComponents/RamblerImage';
import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';
import {
  selectDomainConfig,
  selectIsHumanCenteredStrategyStateActivated,
} from 'common/redux/runtime/selectors';
import { DialogIcon } from 'icons';
import { generateClusterUrl } from 'utils/urlGenerator';

import stylesDefault from './stylesDefault.module.css';

const IMAGE_HEIGHT = 170;

type CarouselSlideRecommendationPropsType = {
  recommendedClusters: RecommendedClustersType;
  styles: StylesType;
};

/**
 * Слайд с рекомендуемыми кластерами.
 * @param recommendedClusters - рекомендательные кластера;
 * @param styles - стили компонента.
 */
export const CarouselSlideRecommendation = memo(
  (props: CarouselSlideRecommendationPropsType) => {
    const { recommendedClusters, styles } = props;

    const domainConfig = useSelector(selectDomainConfig, shallowEqual);
    const forceRedesign = useSelector(
      selectIsHumanCenteredStrategyStateActivated,
    );

    const top100AttributeDefault =
      useTop100AttributeWithValue('recommendations');

    return (
      <ul className={styles.root}>
        {recommendedClusters.map((cluster, index) => {
          const { title, image, id, commentsCount, topicInfo: topic } = cluster;

          const top100Key = Object.keys(top100AttributeDefault)[0];
          const top100Attribute = {
            [top100Key]: `${top100AttributeDefault[top100Key]}::${index}`,
          };

          return (
            <li key={id} className={styles.item}>
              <article className={cn(stylesDefault.article, styles.article)}>
                <div
                  className={cn(
                    styles.imageWrapper,
                    forceRedesign && styles.imageWrapperRedesign,
                  )}
                >
                  {image && (
                    <RamblerImage
                      className={styles.image}
                      classNameError={stylesDefault.image_error}
                      src={image?.url ?? ''}
                      height={IMAGE_HEIGHT}
                      alt={image.description}
                      isS3={image.s3}
                      isLazy
                    />
                  )}
                </div>
                <div className={styles.descriptionWrapper}>
                  <h4 className={styles.title}>
                    <a
                      className={cn(stylesDefault.link, styles.link)}
                      aria-label={`Перейти к новости ${title}`}
                      href={generateClusterUrl({
                        domainConfig,
                        clusterId: cluster.id,
                        normalizedTitle: recommendedClusters[0].normalizedTitle,
                        topic,
                      })}
                      {...top100Attribute}
                    >
                      {title}
                    </a>
                  </h4>
                  <div className={styles.topic}>
                    <span>{topic?.name}</span>
                    {!!commentsCount && (
                      <>
                        <DialogIcon className={styles.icon} />
                        <span aria-label="Количество комментариев">
                          {commentsCount}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </article>
            </li>
          );
        })}
      </ul>
    );
  },
);
