import cn from 'classnames';
import React, { memo } from 'react';

import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';
import { ruStrftime } from 'utils/date/dtime';
import { getClusterDate, getDateWithSlash } from 'utils/date/getMoscowDateTime';

type SourceDatePropsType = {
  date: CardData['publicationTime'];
  customStyles?: StylesType;
};

/**
 * Дата публикации кластера
 * @param date - дата публикации кластера
 * @param customStyles - объект со стилями
 */
export const SourceDate = memo(
  ({ date, customStyles = {} }: SourceDatePropsType) => {
    const top100Attribute = useTop100AttributeWithValue('date');

    // Не показываем дату публикации на отложенных кластерах
    if (!date || new Date(date) > new Date()) return null;

    const pubdate = new Date(date).getTime() / 1000;
    const ariaLabel = `Дата публикации новости: ${getClusterDate(
      date,
    )}. Перейти к новостям за ${ruStrftime(pubdate)}`;

    return (
      <a
        aria-label={ariaLabel}
        className={cn(customStyles.sourceDate)}
        href={getDateWithSlash(date)}
        {...top100Attribute}
      >
        <time dateTime={date}>{getClusterDate(date)}</time>
      </a>
    );
  },
);
