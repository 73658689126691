import cn from 'classnames';
import React, { memo } from 'react';

import { RamblerImage } from 'common/components/RamblerComponents/RamblerImage';
import { ClusterImageObject } from 'common/components/schemaOrg/ImageObject/ClusterImageObject';
import { TextScaleValueType } from 'common/redux/pages/cluster';
import { checkCorrectUrl } from 'utils/checkCorrectUrl';
import { getUrlWithUtmParams } from 'utils/getUrlWithUtmParams';
import { useHideLinksContext } from 'utils/hideLinksContext';
import { addInstBanComment } from 'utils/socialsBan/addBanComment';

import s from './styles.module.css';

const IMAGE_HEIGHT = 400;

type ClusterImagePropsType = {
  runtime: Runtime;
  cluster: ClusterData;
  textScaleValue: TextScaleValueType;
  styles?: StylesType;
  withPhoto?: boolean;
  noImage?: boolean;
};

/**
 * Главное фото кластера и ссылка на его источник
 * @param cluster - Общая информация для приложения
 * @param cluster - объект данных кластера
 * @param textScaleValue - значение маштабируемости текста (от 0 до 3)
 * @param withPhoto - нужна ли подпись "Фото"
 * @param noImage - не отображать картинку. Для кейса на dir1
 */
export const ClusterImage = memo(
  ({
    runtime,
    cluster,
    textScaleValue,
    styles = s,
    withPhoto = true,
    noImage = false,
  }: ClusterImagePropsType) => {
    const { image, title: clusterTitle } = cluster;
    const { source: imageSource, url, description, s3 } = image || {};
    const shouldHideLinks = useHideLinksContext();

    const imageSourceSignUrl = imageSource?.url || '';
    const isNotRamblerImageSource = !imageSourceSignUrl.includes('rambler.ru');

    const schemaOrg = <ClusterImageObject cluster={cluster} />;

    return !noImage ? (
      <div className={styles.mediaSectionImage}>
        {schemaOrg}
        <div className={s.imageWrapper}>
          <RamblerImage
            height={IMAGE_HEIGHT}
            src={url || ''}
            alt={description || clusterTitle}
            isS3={s3}
            className={s.image}
            errorClassName={s.imageError}
          />
        </div>
        {imageSource && (imageSource.title || imageSource.url) && (
          <div
            className={cn(styles.imageDescription, {
              [styles[`imageDescription_${textScaleValue}`]]: textScaleValue,
            })}
          >
            {withPhoto && imageSource.title && <span>©&nbsp;</span>}
            {!shouldHideLinks &&
            imageSource.url &&
            checkCorrectUrl(imageSource.url) ? (
              <a
                className={styles.imageDescriptionLink}
                aria-label={`Перейти на страницу источника ${imageSource.title}`}
                href={
                  !isNotRamblerImageSource
                    ? imageSourceSignUrl
                    : getUrlWithUtmParams({
                        url: imageSourceSignUrl,
                        params: {
                          medium: 'source',
                          source: `r${runtime.project.alias}`,
                        },
                      })
                }
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                {addInstBanComment(imageSource.title || imageSource.url)}
              </a>
            ) : (
              addInstBanComment(imageSource.title || '')
            )}
          </div>
        )}
      </div>
    ) : (
      schemaOrg
    );
  },
);
