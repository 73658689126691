import { InjectorType } from '@media-components/draft-parser';
import _clonedeep from 'lodash.clonedeep';

/**
 * Инжектор озвучки текста кластера.
 * @param blocks - блоки драфта.
 */
export const injectSpeakableId: InjectorType<{}> = ({ blocks }) => {
  const blocksClone = _clonedeep(blocks);
  const paragraphIndex = blocksClone.findIndex(
    (block) => block.type === 'paragraph',
  );

  if (!blocksClone[paragraphIndex]?.data?.id) return blocks;

  blocksClone[paragraphIndex].data!.id = 'summary';

  return blocksClone;
};
