import cn from 'classnames';
import React, {
  MutableRefObject,
  SyntheticEvent,
  memo,
  useCallback,
  useState,
} from 'react';

import { TagLink } from 'common/components/TagList/components/TagLink';
import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';
import { selectIsHumanCenteredStrategyStateActivated } from 'common/redux/runtime/selectors';
import { ArrowDownIcon } from 'icons';
import { useAppSelector } from 'store/hooks';

import s from './styles.module.css';

type TagListLayoutPropsType = {
  markers: MarkerType[];
  outerTagWrapperRef: MutableRefObject<HTMLDivElement | null>;
  isTagsButtonVisible: boolean;
  innerTagWrapperRef: MutableRefObject<HTMLDivElement | null>;
  clusterId: string;
  styles?: StylesType;
};

enum LABEL_FOR_INPUT {
  unchecked = 'Развернуть список тегов',
  checked = 'Свернуть список тегов',
}
/**
 * Шаблон тег-листа с разворачиванием/сворачиванием тегов, содержит только вёрстку, без функциональной части
 * @param markers - массив тегов
 * @param styles - стили
 * @param outerTagWrapperRef - ref внешней обертки над тегами, нужен для хука снаружи
 * @param isTagsButtonVisible - видна ли кнопка разворота тегов
 * @param innerTagWrapperRef - ref внутренней обертки обертки над тегами, нужен для хука снаружи
 * @param clusterId - id кластера для передачи в label и input
 */

export const TagListLayout = memo(
  ({
    markers,
    styles = {},
    outerTagWrapperRef,
    isTagsButtonVisible,
    innerTagWrapperRef,
    clusterId,
  }: TagListLayoutPropsType) => {
    const [ariaLabel, setAriaLabel] = useState(LABEL_FOR_INPUT.unchecked);
    const top100Attribute = useTop100AttributeWithValue('tags_type');
    const forceRedesign = useAppSelector(
      selectIsHumanCenteredStrategyStateActivated,
    );

    const onToggleAriaLabel = useCallback((event: SyntheticEvent) => {
      if ((event.target as HTMLInputElement).checked) {
        setAriaLabel(LABEL_FOR_INPUT.checked);

        return;
      }

      setAriaLabel(LABEL_FOR_INPUT.unchecked);
    }, []);

    return (
      <div
        className={cn(
          s.container,
          styles.container,
          forceRedesign && s.redesign,
        )}
      >
        {isTagsButtonVisible && (
          <>
            <input
              id={clusterId}
              onClick={onToggleAriaLabel}
              name="tagsButton"
              type="checkbox"
              className={cn(s.tagsButton, styles.tagsButton)}
            />
            <label
              htmlFor={clusterId}
              aria-label={ariaLabel}
              className={cn(
                s.tagsButtonIconWrapper,
                styles.tagsButtonIconWrapper,
              )}
            >
              <ArrowDownIcon className={cn(s.icon, styles.icon)} />
            </label>
          </>
        )}
        <div
          ref={outerTagWrapperRef}
          className={cn(s.tagsContainer, styles.tagsContainer)}
        >
          <div ref={innerTagWrapperRef} className={s.tagInner}>
            {markers
              .filter((item) => item.name)
              .map(({ type, id, alias, name }, index) => (
                <div
                  key={`${id}-${alias}`}
                  className={cn(s.tagWrapper, styles.tagWrapper)}
                  {...top100Attribute}
                >
                  <TagLink
                    type={type}
                    id={id}
                    alias={alias}
                    name={name}
                    position={index + 1}
                    styles={styles}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  },
);
