import React, { memo } from 'react';
import { ImageObject } from 'schema-dts';

import { JsonLd } from 'utils/jsond';

import { getItemForImageObject } from '../utils';

type SlideImageObjectPropsType = {
  imgUrls: string[];
  name: string | undefined;
  description: string | undefined;
  author: string;
  isMain: boolean;
};

/**
 * Получение ImageObject schema.org для слайда галереи
 * @params imgUrls - url изображения
 * @params name - длиный заголовок кластера
 * @params description - описание слайда или длинный заголовок кластера
 * @params author - название источника
 * @params isMain - указывает что слайд главный на странице
 * @returns - блок schema.org описывающий ImageObject
 */
export const SlideImageObject = memo(
  ({ imgUrls, name, description, author }: SlideImageObjectPropsType) => {
    const item = getItemForImageObject({
      imgUrls,
      name,
      description,
      imageSource: author,
    });

    return <JsonLd<ImageObject> item={item} />;
  },
);
