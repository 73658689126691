import React, { memo } from 'react';

import { RcmWidget } from 'common/components/RCMWidget';
import {
  WidgetLayoutTypeV3,
  WidgetTemplate,
} from 'common/components/RCMWidget/typings';
import { RESOURCES_WITH_RNET } from 'config/constants/resourceRnet';

type ResourceRelatedPropsType = {
  clusterId: ClusterData['id'];
  resourceId: RESOURCES_WITH_RNET;
  customStyles?: StylesType;
};

/**
 * Комопент последних новостей для определенных ресурсов
 * @param clusterId - id кластера
 * @param resourceId - id источника кластера
 */
export const ResourceRelated = memo(
  ({ clusterId, resourceId }: ResourceRelatedPropsType) => (
    <RcmWidget
      resourceId={resourceId}
      templateName={WidgetTemplate.v3}
      layoutType={WidgetLayoutTypeV3.Horizontal}
      clusterId={clusterId}
    />
  ),
);
