import cn from 'classnames';
import React, { memo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';
import {
  selectClusterRelated,
  selectClusterResource,
} from 'common/redux/commonData/entries/selectors';
import { selectClusterPageTextScale } from 'common/redux/pages/cluster/selectors';
import { useAppSelector } from 'store/hooks';

import { Item } from './components/Item';

type RelatedDir1PropsType = {
  clusterId: CardData['id'];
  hiddenTitle?: boolean;
  styles?: { [className: string]: string };
  top100?: string;
};

/**
 * Последние новости
 * @param clusterId - id кластера, для которого рисуется компонент;
 * @param hiddenTitle - флаг видимости заголовка;
 * @param styles - дополнительные стили для компонента;
 * @param top100 - значение аттрибута топ100 для разметки.
 */
export const RelatedDir1 = memo(
  ({ clusterId, hiddenTitle, styles = {}, top100 }: RelatedDir1PropsType) => {
    const news = useAppSelector(selectClusterRelated(clusterId), shallowEqual);
    const resource = useSelector(
      selectClusterResource(clusterId),
      shallowEqual,
    );
    const textScaleValue = useSelector(selectClusterPageTextScale);

    const top100Attribute = useTop100AttributeWithValue(top100);

    if (!news?.length) return null;

    return (
      <div className={styles.container} {...top100Attribute}>
        {!hiddenTitle && !!resource?.title && (
          <div
            className={cn(styles.title, styles[`title_${textScaleValue}`])}
            data-test="resource-title"
          >
            {`${resource.title}: последние новости`}
          </div>
        )}
        {news.map(({ title, url }, index) => (
          <Item
            key={`${title}-${url}`}
            title={title}
            url={url}
            index={index}
            favicon={resource?.favicon}
            styles={styles}
          />
        ))}
      </div>
    );
  },
);
